const WALLPAPER_C1_1 = () => {
  return (
    <g id="WALLPAPER">
      <path
        id="C1.1"
        className="label-huge"
        d="M178.57,225.75l-3.5,1a11.25,11.25,0,0,0-2.43-3.22,11.64,11.64,0,0,0-3.35-2.22,12,12,0,0,0-4-1,12.82,12.82,0,0,0-4.38.48,12.64,12.64,0,0,0-6.07,3.73,13.77,13.77,0,0,0-3.2,6.86,20.79,20.79,0,0,0,.72,9.4,21.12,21.12,0,0,0,4.27,8.45,13.74,13.74,0,0,0,6.29,4.18,12.55,12.55,0,0,0,7.11,0,12.91,12.91,0,0,0,4-1.86,12.19,12.19,0,0,0,2.93-2.89,11.8,11.8,0,0,0,2.12-7.66l3.5-1a15.94,15.94,0,0,1-.38,5.39,15.26,15.26,0,0,1-5.91,8.76,16.58,16.58,0,0,1-5.34,2.52,16,16,0,0,1-9.21,0,17.08,17.08,0,0,1-7.75-5.18,27.6,27.6,0,0,1-5.8-20.75,16.94,16.94,0,0,1,3.95-8.44,15.83,15.83,0,0,1,7.85-4.8,16.55,16.55,0,0,1,5.88-.62,14.67,14.67,0,0,1,5.24,1.38,15.25,15.25,0,0,1,4.35,3.05A15.45,15.45,0,0,1,178.57,225.75Zm15.8-17.27L205,246.63l-3.49,1-9.64-34.5-.22.06-7.28,8.45-1-3.62,7.34-8.5Zm22.85,35a2.72,2.72,0,0,1-2.12-.25,2.75,2.75,0,0,1-1.31-1.68,2.77,2.77,0,0,1,1.93-3.43,2.78,2.78,0,0,1,3.44,1.93,2.85,2.85,0,0,1-.7,2.7A2.63,2.63,0,0,1,217.22,243.52Zm7.89-43.64L235.77,238l-3.48,1-9.64-34.5-.23.06L215.14,213l-1-3.61,7.35-8.5Z"
        transform="translate(-50.14 -199.88)"
      />
      <g className="map-groups-stairs">
        <polyline points="314.29 152.1 326.62 235.61 332.02 234.82 328.33 209.05 337.75 207.68 341.46 233.44 346.95 232.65 334.58 148.85" />
        <polyline points="298.89 48.53 312.28 138.56 317.72 137.59 314.03 111.82 323.45 110.45 327.17 136.22 332.58 135.34 319.3 45.78" />
        <g>
          <path
            d="M413,780.34H400a1.5,1.5,0,0,0-1.5,1.5v13a1.5,1.5,0,0,0,1.5,1.5h13a1.5,1.5,0,0,0,1.5-1.5v-13A1.5,1.5,0,0,0,413,780.34Z"
            transform="translate(-72.19 -567.47)"
          />
          <path
            d="M410,784.84v7h-7v-7Zm0-1h-7a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1v-7A1,1,0,0,0,410,783.84Zm-.5,1.5h-2.21V787H406v1.67H404.7v1.67h-1.21v1h2.21v-1.67H407V788h1.29v-1.67h1.21Z"
            transform="translate(-72.19 -567.47)"
          />
        </g>
        <g>
          <path
            d="M399.75,684.35h-13a1.5,1.5,0,0,0-1.5,1.5v13a1.5,1.5,0,0,0,1.5,1.5h13a1.5,1.5,0,0,0,1.5-1.5v-13A1.5,1.5,0,0,0,399.75,684.35Z"
            transform="translate(-72.19 -567.47)"
          />
          <path
            d="M396.75,688.85v7h-7v-7Zm0-1h-7a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1v-7A1,1,0,0,0,396.75,687.85Zm-.5,1.5H394V691h-1.29v1.66h-1.29v1.67h-1.21v1h2.21v-1.66h1.29V692H395v-1.67h1.21Z"
            transform="translate(-72.19 -567.47)"
          />
        </g>
      </g>
      <path
        id="C1.1.BG"
        className="bg"
        d="M345.35,223.23S238.19,236.7,85.94,293.82C61.26,303.08,41,318,56.6,349.53L169.65,553.15C206.77,537,206.77,537,245.1,523.69l-3.82-11.45,73.31-21.05L317.1,503c32.67-7.93,32.67-7.93,67.75-13.24Z"
        transform="translate(-50.14 -199.88)"
      />
    </g>
  );
};

export default WALLPAPER_C1_1;
