const ICONS_C1_2 = ({ isFirst, isSecond, isThird }) => {
  return (
    <>
      {isFirst && (
        <>
          <g className="map-groups-escapes-icon">
            <path
              d="M582.68,809.83l-13-.15a1.5,1.5,0,0,0-1.52,1.48l-.15,13a1.5,1.5,0,0,0,1.48,1.51l13,.16a1.5,1.5,0,0,0,1.52-1.48l.15-13A1.5,1.5,0,0,0,582.68,809.83Z"
              transform="translate(-370.59 -526.44)"
            />
            <path
              d="M577.4,814.39a1,1,0,0,0,1-1,1,1,0,0,0-1-1,1,1,0,0,0-1,1A1,1,0,0,0,577.4,814.39Zm-1.88,6.93.53-2.19,1,1,0,3h1l0-3.75-1-1,.32-1.5a3.69,3.69,0,0,0,2.74,1.29v-1A2.5,2.5,0,0,1,578,816l-.49-.81a1,1,0,0,0-.84-.51c-.15,0-.25,0-.4,0l-2.61,1.07,0,2.35h1l0-1.7.9-.34-.85,4-2.44-.53-.21,1Z"
              transform="translate(-370.59 -526.44)"
            />
          </g>

          <g className="map-groups-escapes-icon">
            <path
              d="M499.8,809.83l-13-.15a1.5,1.5,0,0,0-1.52,1.48l-.15,13a1.5,1.5,0,0,0,1.48,1.51l13,.16a1.49,1.49,0,0,0,1.51-1.48l.16-13A1.5,1.5,0,0,0,499.8,809.83Z"
              transform="translate(-370.59 -526.44)"
            />
            <path
              d="M494.52,814.39a1,1,0,0,0,1-1,1,1,0,1,0-2,0A1,1,0,0,0,494.52,814.39Zm-1.88,6.93.52-2.19,1,1,0,3h1l0-3.75-1-1,.32-1.5a3.67,3.67,0,0,0,2.73,1.29l0-1A2.5,2.5,0,0,1,495.1,816l-.49-.81a1,1,0,0,0-.84-.51c-.15,0-.26,0-.41,0l-2.61,1.07,0,2.35h1l0-1.7.91-.34-.85,4-2.44-.53-.22,1Z"
              transform="translate(-370.59 -526.44)"
            />
          </g>

          <g className="map-groups-wcs-icon">
            <path
              d="M530.24,654.5l-13-.15a1.5,1.5,0,0,0-1.52,1.48l-.16,13a1.5,1.5,0,0,0,1.48,1.52l13,.15a1.5,1.5,0,0,0,1.52-1.48l.16-13A1.51,1.51,0,0,0,530.24,654.5Z"
              transform="translate(-370.59 -526.44)"
            />
            <path
              d="M527.19,659l-.08,7-7-.09.08-7Zm0-1-7-.09a1,1,0,0,0-1,1l-.08,7a1,1,0,0,0,1,1l7,.09a1,1,0,0,0,1-1l.08-7A1,1,0,0,0,527.2,658Zm-4.59,7.44,0-2h.5v-1.25a1,1,0,0,0-1-1h-.5a1,1,0,0,0-1,1v1.25h.5l0,2Zm-.69-4.76a.61.61,0,0,0,.63-.61.63.63,0,0,0-.61-.64.64.64,0,0,0-.64.62A.63.63,0,0,0,521.92,660.65Zm4.73,1.31-1.22-2-1.28,2Zm-2.51,1,1.23,2,1.27-2Z"
              transform="translate(-370.59 -526.44)"
            />
          </g>

          <g className="map-groups-wcs-icon">
            <path
              d="M504.24,659.19l-13-.15a1.5,1.5,0,0,0-1.52,1.48l-.16,13a1.51,1.51,0,0,0,1.49,1.52l13,.15a1.5,1.5,0,0,0,1.52-1.48l.16-13A1.5,1.5,0,0,0,504.24,659.19Z"
              transform="translate(-370.59 -526.44)"
            />
            <path
              d="M498.58,672.13l0-3h1.5l-1.23-3.83a1,1,0,0,0-.94-.69h-.06a1,1,0,0,0-1,.67l-1.31,3.8,1.5,0,0,3Zm-.65-8a1,1,0,0,0,1-1,1,1,0,0,0-1-1,1,1,0,0,0,0,2Z"
              transform="translate(-370.59 -526.44)"
            />
          </g>

          <g className="map-groups-wcs-icon">
            <path
              d="M574,657.67l-13-.16a1.5,1.5,0,0,0-1.51,1.48l-.16,13a1.5,1.5,0,0,0,1.48,1.52l13,.15a1.5,1.5,0,0,0,1.52-1.48l.16-13A1.5,1.5,0,0,0,574,657.67Z"
              transform="translate(-370.59 -526.44)"
            />
            <path
              d="M566.56,670.58l0-3.75h-.75l0-2.75a1,1,0,0,1,1-1l1.5,0a1,1,0,0,1,1,1l0,2.75h-.75l0,3.75Zm1.09-8a1,1,0,0,0,1-1,1,1,0,0,0-1-1,1,1,0,0,0-1,1A1,1,0,0,0,567.65,662.59Z"
              transform="translate(-370.59 -526.44)"
            />
          </g>
        </>
      )}

      {isSecond && (
        <>
          <g className="map-groups-escapes-icon">
            <path
              d="M556.74,639.44l-13-.16a1.5,1.5,0,0,0-1.52,1.48l-.16,13a1.5,1.5,0,0,0,1.48,1.52l13,.16a1.5,1.5,0,0,0,1.52-1.48l.16-13A1.51,1.51,0,0,0,556.74,639.44Z"
              transform="translate(-370.59 -526.44)"
            />
            <path
              d="M551.46,644a1,1,0,1,0,0-2,1,1,0,0,0,0,2Zm-1.88,6.93.53-2.2,1,1,0,3h1l0-3.75-1-1,.31-1.5a3.67,3.67,0,0,0,2.74,1.28v-1a2.45,2.45,0,0,1-2.13-1.22l-.49-.81a1.07,1.07,0,0,0-.85-.51c-.15,0-.25,0-.4,0l-2.61,1.07,0,2.35h1l0-1.7.9-.34-.84,4-2.45-.53-.21,1Z"
              transform="translate(-370.59 -526.44)"
            />
          </g>

          <g className="map-groups-escapes-icon">
            <path
              d="M582.68,809.83l-13-.15a1.5,1.5,0,0,0-1.52,1.48l-.15,13a1.5,1.5,0,0,0,1.48,1.51l13,.16a1.5,1.5,0,0,0,1.52-1.48l.15-13A1.5,1.5,0,0,0,582.68,809.83Z"
              transform="translate(-370.59 -526.44)"
            />
            <path
              d="M577.4,814.39a1,1,0,0,0,1-1,1,1,0,0,0-1-1,1,1,0,0,0-1,1A1,1,0,0,0,577.4,814.39Zm-1.88,6.93.53-2.19,1,1,0,3h1l0-3.75-1-1,.32-1.5a3.69,3.69,0,0,0,2.74,1.29v-1A2.5,2.5,0,0,1,578,816l-.49-.81a1,1,0,0,0-.84-.51c-.15,0-.25,0-.4,0l-2.61,1.07,0,2.35h1l0-1.7.9-.34-.85,4-2.44-.53-.21,1Z"
              transform="translate(-370.59 -526.44)"
            />
          </g>

          <g className="map-groups-escapes-icon">
            <path
              d="M499.8,809.83l-13-.15a1.5,1.5,0,0,0-1.52,1.48l-.15,13a1.5,1.5,0,0,0,1.48,1.51l13,.16a1.49,1.49,0,0,0,1.51-1.48l.16-13A1.5,1.5,0,0,0,499.8,809.83Z"
              transform="translate(-370.59 -526.44)"
            />
            <path
              d="M494.52,814.39a1,1,0,0,0,1-1,1,1,0,1,0-2,0A1,1,0,0,0,494.52,814.39Zm-1.88,6.93.52-2.19,1,1,0,3h1l0-3.75-1-1,.32-1.5a3.67,3.67,0,0,0,2.73,1.29l0-1A2.5,2.5,0,0,1,495.1,816l-.49-.81a1,1,0,0,0-.84-.51c-.15,0-.26,0-.41,0l-2.61,1.07,0,2.35h1l0-1.7.91-.34-.85,4-2.44-.53-.22,1Z"
              transform="translate(-370.59 -526.44)"
            />
          </g>

          <g className="map-groups-wcs-icon">
            <path
              d="M530.24,654.5l-13-.15a1.5,1.5,0,0,0-1.52,1.48l-.16,13a1.5,1.5,0,0,0,1.48,1.52l13,.15a1.5,1.5,0,0,0,1.52-1.48l.16-13A1.51,1.51,0,0,0,530.24,654.5Z"
              transform="translate(-370.59 -526.44)"
            />
            <path
              d="M527.19,659l-.08,7-7-.09.08-7Zm0-1-7-.09a1,1,0,0,0-1,1l-.08,7a1,1,0,0,0,1,1l7,.09a1,1,0,0,0,1-1l.08-7A1,1,0,0,0,527.2,658Zm-4.59,7.44,0-2h.5v-1.25a1,1,0,0,0-1-1h-.5a1,1,0,0,0-1,1v1.25h.5l0,2Zm-.69-4.76a.61.61,0,0,0,.63-.61.63.63,0,0,0-.61-.64.64.64,0,0,0-.64.62A.63.63,0,0,0,521.92,660.65Zm4.73,1.31-1.22-2-1.28,2Zm-2.51,1,1.23,2,1.27-2Z"
              transform="translate(-370.59 -526.44)"
            />
          </g>

          <g className="map-groups-wcs-icon">
            <path
              d="M504.24,659.19l-13-.15a1.5,1.5,0,0,0-1.52,1.48l-.16,13a1.51,1.51,0,0,0,1.49,1.52l13,.15a1.5,1.5,0,0,0,1.52-1.48l.16-13A1.5,1.5,0,0,0,504.24,659.19Z"
              transform="translate(-370.59 -526.44)"
            />
            <path
              d="M498.58,672.13l0-3h1.5l-1.23-3.83a1,1,0,0,0-.94-.69h-.06a1,1,0,0,0-1,.67l-1.31,3.8,1.5,0,0,3Zm-.65-8a1,1,0,0,0,1-1,1,1,0,0,0-1-1,1,1,0,0,0,0,2Z"
              transform="translate(-370.59 -526.44)"
            />
          </g>

          <g className="map-groups-wcs-icon">
            <path
              d="M574,657.67l-13-.16a1.5,1.5,0,0,0-1.51,1.48l-.16,13a1.5,1.5,0,0,0,1.48,1.52l13,.15a1.5,1.5,0,0,0,1.52-1.48l.16-13A1.5,1.5,0,0,0,574,657.67Z"
              transform="translate(-370.59 -526.44)"
            />
            <path
              d="M566.56,670.58l0-3.75h-.75l0-2.75a1,1,0,0,1,1-1l1.5,0a1,1,0,0,1,1,1l0,2.75h-.75l0,3.75Zm1.09-8a1,1,0,0,0,1-1,1,1,0,0,0-1-1,1,1,0,0,0-1,1A1,1,0,0,0,567.65,662.59Z"
              transform="translate(-370.59 -526.44)"
            />
          </g>

          <g className="map-groups-wcs-icon"></g>

          <g className="map-groups-wcs-icon"></g>

          <g className="map-groups-wcs-icon"></g>
        </>
      )}

      {isThird && (
        <>
          <g className="map-groups-escapes-icon">
            <path
              d="M556.74,639.44l-13-.16a1.5,1.5,0,0,0-1.52,1.48l-.16,13a1.5,1.5,0,0,0,1.48,1.52l13,.16a1.5,1.5,0,0,0,1.52-1.48l.16-13A1.51,1.51,0,0,0,556.74,639.44Z"
              transform="translate(-370.59 -526.44)"
            />
            <path
              d="M551.46,644a1,1,0,1,0,0-2,1,1,0,0,0,0,2Zm-1.88,6.93.53-2.2,1,1,0,3h1l0-3.75-1-1,.31-1.5a3.67,3.67,0,0,0,2.74,1.28v-1a2.45,2.45,0,0,1-2.13-1.22l-.49-.81a1.07,1.07,0,0,0-.85-.51c-.15,0-.25,0-.4,0l-2.61,1.07,0,2.35h1l0-1.7.9-.34-.84,4-2.45-.53-.21,1Z"
              transform="translate(-370.59 -526.44)"
            />
          </g>

          <g className="map-groups-escapes-icon">
            <path
              d="M499.8,809.83l-13-.15a1.5,1.5,0,0,0-1.52,1.48l-.15,13a1.5,1.5,0,0,0,1.48,1.51l13,.16a1.49,1.49,0,0,0,1.51-1.48l.16-13A1.5,1.5,0,0,0,499.8,809.83Z"
              transform="translate(-370.59 -526.44)"
            />
            <path
              d="M494.52,814.39a1,1,0,0,0,1-1,1,1,0,1,0-2,0A1,1,0,0,0,494.52,814.39Zm-1.88,6.93.52-2.19,1,1,0,3h1l0-3.75-1-1,.32-1.5a3.67,3.67,0,0,0,2.73,1.29l0-1A2.5,2.5,0,0,1,495.1,816l-.49-.81a1,1,0,0,0-.84-.51c-.15,0-.26,0-.41,0l-2.61,1.07,0,2.35h1l0-1.7.91-.34-.85,4-2.44-.53-.22,1Z"
              transform="translate(-370.59 -526.44)"
            />
          </g>

          <g className="map-groups-wcs-icon">
            <path
              d="M530.24,654.5l-13-.15a1.5,1.5,0,0,0-1.52,1.48l-.16,13a1.5,1.5,0,0,0,1.48,1.52l13,.15a1.5,1.5,0,0,0,1.52-1.48l.16-13A1.51,1.51,0,0,0,530.24,654.5Z"
              transform="translate(-370.59 -526.44)"
            />
            <path
              d="M527.19,659l-.08,7-7-.09.08-7Zm0-1-7-.09a1,1,0,0,0-1,1l-.08,7a1,1,0,0,0,1,1l7,.09a1,1,0,0,0,1-1l.08-7A1,1,0,0,0,527.2,658Zm-4.59,7.44,0-2h.5v-1.25a1,1,0,0,0-1-1h-.5a1,1,0,0,0-1,1v1.25h.5l0,2Zm-.69-4.76a.61.61,0,0,0,.63-.61.63.63,0,0,0-.61-.64.64.64,0,0,0-.64.62A.63.63,0,0,0,521.92,660.65Zm4.73,1.31-1.22-2-1.28,2Zm-2.51,1,1.23,2,1.27-2Z"
              transform="translate(-370.59 -526.44)"
            />
          </g>

          <g className="map-groups-wcs-icon">
            <path
              d="M504.24,659.19l-13-.15a1.5,1.5,0,0,0-1.52,1.48l-.16,13a1.51,1.51,0,0,0,1.49,1.52l13,.15a1.5,1.5,0,0,0,1.52-1.48l.16-13A1.5,1.5,0,0,0,504.24,659.19Z"
              transform="translate(-370.59 -526.44)"
            />
            <path
              d="M498.58,672.13l0-3h1.5l-1.23-3.83a1,1,0,0,0-.94-.69h-.06a1,1,0,0,0-1,.67l-1.31,3.8,1.5,0,0,3Zm-.65-8a1,1,0,0,0,1-1,1,1,0,0,0-1-1,1,1,0,0,0,0,2Z"
              transform="translate(-370.59 -526.44)"
            />
          </g>

          <g className="map-groups-wcs-icon">
            <path
              d="M574,657.67l-13-.16a1.5,1.5,0,0,0-1.51,1.48l-.16,13a1.5,1.5,0,0,0,1.48,1.52l13,.15a1.5,1.5,0,0,0,1.52-1.48l.16-13A1.5,1.5,0,0,0,574,657.67Z"
              transform="translate(-370.59 -526.44)"
            />
            <path
              d="M566.56,670.58l0-3.75h-.75l0-2.75a1,1,0,0,1,1-1l1.5,0a1,1,0,0,1,1,1l0,2.75h-.75l0,3.75Zm1.09-8a1,1,0,0,0,1-1,1,1,0,0,0-1-1,1,1,0,0,0-1,1A1,1,0,0,0,567.65,662.59Z"
              transform="translate(-370.59 -526.44)"
            />
          </g>
        </>
      )}
    </>
  );
};

export default ICONS_C1_2;
