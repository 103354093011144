import IconsEscapesSecondThird from "../general/map/IconsEscapesSecondThird";
import IconsEscapeFirstSecond from "../general/map/IconsEscapesFirstSecond";
import MapLayout from "../layout/MapLayout";
import Stairs from "../general/map/Stairs";

const SecondFloorAll = () => {
  return (
    <MapLayout>
      <g id="SECOND_FLOOR_IOS">
        <g id="C1.3">
          <g className="map-groups-rooms">
            <g id="C1.3.267P" data-name="C1.3.267P">
              <polyline points="775.83 316.2 773.22 315.23 781.36 293.03 808.04 303.16 798.82 324.84 779.38 317.64" />
              <text
                className="fit-text"
                transform="translate(780.42 312.18) scale(0.98 1)"
              >
                267P
              </text>
            </g>
            <g id="C1.3.266P" data-name="C1.3.266P">
              <polyline points="766.83 313.24 749.13 307.06 756.79 284.43 781.36 293.03 773.22 315.23 770.22 314.28" />
              <text
                className="fit-text"
                transform="translate(754.08 303.79) scale(0.98 1)"
              >
                266P
              </text>
            </g>
            <g id="C1.3.264L" data-name="C1.3.264L">
              <line x1="765.2" y1="325.8" x2="787.84" y2="334.25" />
              <polyline points="791.21 335.51 793.99 336.55 775.65 381.74 744.65 370.02 761.45 324.4 763.16 325.04" />
              <text transform="translate(757.11 357.02) scale(0.98 1)">
                264L
              </text>
            </g>
            <g id="C1.3.263P" data-name="C1.3.263P">
              <polyline points="791.4 354.98 819.96 367.92 805 394.87 775.65 381.74 787.27 353.12 788.6 353.72" />
              <text transform="matrix(0.89, 0.41, -0.4, 0.92, 783.94, 373.4)">
                263P
              </text>
            </g>
            <g id="C1.3.262P" data-name="C1.3.262P">
              <polyline points="798.87 351.85 807.34 333.83 832.38 345.55 819.96 367.92 796.3 357.05 797.06 355.5" />
              <text
                className="fit-text"
                transform="matrix(0.88, 0.44, -0.43, 0.9, 803.63, 349.55)"
              >
                262P
              </text>
            </g>
            <g id="C1.3.261P" data-name="C1.3.261P">
              <polyline points="811.78 324.04 814.28 318.72 840.51 330.92 832.38 345.55 807.34 333.83 809.29 329.37" />
              <text
                className="fit-text"
                transform="matrix(0.88, 0.44, -0.43, 0.9, 813.54, 330.94)"
              >
                261P
              </text>
            </g>
            <g id="C1.3.260P" data-name="C1.3.260P">
              <polyline points="818.35 310.02 821 304.37 848.25 316.97 840.51 330.92 814.28 318.72 816.56 313.86" />
              <text
                className="fit-text"
                transform="matrix(0.88, 0.44, -0.43, 0.9, 821.06, 316.99)"
              >
                260P
              </text>
            </g>
            <g id="C1.3.259P" data-name="C1.3.259P">
              <polyline points="824.87 296.13 827.45 290.62 855.69 303.56 848.25 316.97 821 304.37 822.96 300.2" />
              <text
                className="fit-text"
                transform="matrix(0.89, 0.43, -0.42, 0.91, 828.48, 303.33)"
              >
                259P
              </text>
            </g>
            <g id="C1.3.258P" data-name="C1.3.258P">
              <polyline points="831.88 281.18 834.63 275.31 863.99 288.61 855.69 303.56 827.45 290.62 830.23 284.69" />
              <text
                className="fit-text"
                transform="matrix(0.9, 0.41, -0.39, 0.92, 835.62, 288.62)"
              >
                258P
              </text>
            </g>
            <g id="C1.3.257P" data-name="C1.3.257P">
              <polyline points="838.85 266.51 841.45 260.75 871.76 274.55 863.99 288.61 834.63 275.31 837.14 269.95" />
              <text
                className="fit-text"
                transform="matrix(0.9, 0.41, -0.4, 0.92, 842.95, 274.28)"
              >
                257P
              </text>
            </g>
            <g id="C1.3.255L" data-name="C1.3.255L">
              <polyline points="825.05 256.61 827.16 257.44 808.04 303.16 781.36 293.03 798.98 246.19 802.54 247.62" />
              <line x1="805.66" y1="248.86" x2="821.45" y2="255.17" />
              <text
                className="fit-text"
                transform="translate(793.65 280.98) scale(0.98 1)"
              >
                255L
              </text>
            </g>
            <g id="C1.3.254L" data-name="C1.3.254L">
              <polyline points="796.13 245.21 798.98 246.19 781.36 293.03 756.79 284.43 772.48 237.02 775.53 238.07" />
              <line x1="777.73" y1="238.83" x2="791.26" y2="243.52" />
              <text
                className="fit-text"
                transform="translate(766.33 269.65) scale(0.98 1)"
              >
                254L
              </text>
            </g>
            <g id="C1.3.253K" data-name="C1.3.253K">
              <polyline points="845.7 251.69 848.39 245.97 879.33 260.99 871.72 274.66 841.45 260.75 843.98 255.37" />
              <text
                className="fit-text"
                transform="matrix(0.89, 0.42, -0.41, 0.91, 850.99, 260.27)"
              >
                253K
              </text>
            </g>
            <g id="C1.3.252K" data-name="C1.3.252K">
              <polyline points="852.3 237.63 854.65 232.6 886.95 247.26 879.33 260.99 848.3 246.15 850.7 241.03" />
              <text
                className="fit-text"
                transform="matrix(0.89, 0.43, -0.42, 0.91, 857.82, 245.92)"
              >
                252K
              </text>
            </g>
            <g id="C1.3.251K" data-name="C1.3.251K">
              <polyline points="859.13 223.06 861.93 217.08 894.83 233.06 886.95 247.26 854.65 232.6 857.57 226.38" />
              <text
                className="fit-text"
                transform="matrix(0.89, 0.41, -0.4, 0.92, 863.99, 232.18)"
              >
                251K
              </text>
            </g>
            <g id="C1.3.250K" data-name="C1.3.250K">
              <polyline points="866.06 208.24 868.33 203.51 902.9 218.53 894.83 233.06 861.93 217.08 864.36 211.89" />
              <text
                className="fit-text"
                transform="matrix(0.89, 0.41, -0.4, 0.92, 871.81, 217.58)"
              >
                250K
              </text>
            </g>
            <g id="C1.3.249K" data-name="C1.3.249K">
              <polyline points="870.13 199.68 868.33 203.51 902.9 218.53 909.84 206.04 874.34 190.66 872.45 194.6" />
              <text
                className="fit-text"
                transform="matrix(0.9, 0.4, -0.38, 0.92, 878.38, 203.46)"
              >
                249K
              </text>
            </g>
            <g id="C1.3.248P" data-name="C1.3.248P">
              <polyline points="823.09 229.85 839.56 236.42 849.6 215.91 824.67 206.21 816.82 227.34 819.65 228.48" />
              <text
                className="fit-text"
                transform="translate(823.77 223.99) scale(0.98 1)"
              >
                248P
              </text>
            </g>
            <g id="C1.3.247P" data-name="C1.3.247P">
              <polyline points="621.71 290.25 624.31 290.61 616.75 335.01 590.12 330.86 596.66 286.7 618.48 289.79" />
              <text
                className="fit-text"
                transform="translate(596.53 314.79) scale(0.98 1)"
              >
                247P
              </text>
            </g>
            <g id="C1.3.246" data-name="C1.3.246">
              <polyline points="646.66 295.37 649.41 295.95 640.69 339.95 616.75 335.01 624.31 290.61 643.83 294.76" />
              <text
                className="fit-text"
                transform="translate(622.26 318.83) scale(0.98 1)"
              >
                246P
              </text>
            </g>
            <g id="C1.3.244P" data-name="C1.3.244P">
              <polyline points="640.7 271.33 645.56 241.46 678.24 248.71 670.17 286.53 639.27 280.13 640.17 274.6" />
              <text transform="translate(647.51 267.04) scale(0.98 1)">
                244P
              </text>
            </g>
            <g id="C1.3.243P" data-name="C1.3.243P">
              <polyline points="632.32 246.32 626.88 278.49 598.48 274.44 604.35 234.83 633.4 239.87 632.99 242.21" />
              <text transform="translate(604.35 261.18) scale(0.98 1)">
                243P
              </text>
            </g>
            <g id="C1.3.241P" data-name="C1.3.241P">
              <polyline points="646.67 235.46 652.34 204.94 686.04 212.2 678.24 248.71 645.56 241.46 646.11 238.48" />
              <text transform="translate(653.42 230.97) scale(0.98 1)">
                241P
              </text>
            </g>
            <g id="C1.3.240P" data-name="C1.3.240P">
              <polyline points="638.12 211.33 633.4 239.87 604.35 234.83 609.57 199.62 639.6 203.76 639.04 206.96" />
              <text transform="translate(609.71 224.95) scale(0.98 1)">
                240P
              </text>
            </g>
            <g id="C1.3.237P" data-name="C1.3.237P">
              <polyline points="643.46 155.91 639.77 180.07 613.03 176.28 617.64 145.21 644.6 149.26 644.12 152.03" />
              <text
                className="fit-text"
                transform="translate(618.48 165.89) scale(0.98 1)"
              >
                237P
              </text>
            </g>
            <g id="C1.3.234K" data-name="C1.3.234K">
              <polyline points="794.13 219.55 816.82 227.34 835.65 176.66 800.33 164.79 783.52 215.91 790.76 218.39" />
              <text transform="translate(795.17 200.51) scale(0.98 1)">
                234K
              </text>
            </g>
            <g id="C1.3.233P" data-name="C1.3.233P">
              <polyline points="842.77 179.16 863.12 186.49 849.6 215.91 824.67 206.21 835.65 176.66 839.56 178.03" />
              <text
                className="fit-text"
                transform="translate(833.21 200.51) scale(0.98 1)"
              >
                233P
              </text>
            </g>
            <g id="C1.3.235L" data-name="C1.3.235L">
              <polyline points="652.8 166.72 659.8 126.77 697.31 135.47 684.46 189.34 650.25 181.76 651.62 173.12" />
              <text transform="translate(662.94 158.63) scale(0.98 1)">
                235L
              </text>
            </g>
            <g id="C1.3.236P" data-name="C1.3.236P">
              <polyline points="647.6 131.13 644.73 149.26 617.64 145.21 621.35 119.64 648.72 124.5 648.23 127.14" />
              <text
                className="fit-text"
                transform="translate(621.97 139.15) scale(0.98 1)"
              >
                236P
              </text>
            </g>
            <g id="C1.3.232P" data-name="C1.3.232P">
              <polyline points="885.21 179.24 883.43 178.52 896.9 146.86 899.29 147.57 902.12 140.78 911 146.49 917.61 152.98 922.57 162.43 923.69 170.45 922.74 178.52 920.49 185.75 917.43 192.36 890.04 181.21" />
              <text transform="translate(894.63 172.33) scale(0.98 1)">
                232P
              </text>
            </g>
            <g id="C1.3.231P" data-name="C1.3.231P">
              <polyline points="881.44 177.72 883.43 178.52 896.9 146.86 899.29 147.57 902.12 140.78 870.99 128.32 854.58 167.93 877.95 176.42" />
              <text transform="matrix(0.42, -0.93, 0.9, 0.43, 877.16, 165.86)">
                231P
              </text>
            </g>
            <g id="C1.3.230P" data-name="C1.3.230P">
              <polyline points="849.03 165.98 827.31 158.55 841.88 118.19 870.99 128.32 854.58 167.93 852.46 167.18" />
              <text transform="matrix(0.37, -0.95, 0.93, 0.37, 850.91, 154.81)">
                230P
              </text>
            </g>
            <g id="C1.3.229P" data-name="C1.3.229P">
              <polyline points="825.6 157.98 827.31 158.55 841.88 118.19 811.57 108.19 798.03 149.11 822.2 156.85" />
              <text transform="matrix(0.3, -0.97, 0.95, 0.31, 822.17, 144.54)">
                229P
              </text>
            </g>
            <g id="C1.3.228K" data-name="C1.3.228K">
              <polyline points="796.58 148.66 798.03 149.11 811.57 108.19 787.92 100.92 775.66 142.33 793.17 147.6" />
              <text transform="matrix(0.3, -0.97, 0.95, 0.31, 795.15, 135.61)">
                228K
              </text>
            </g>
            <g id="C1.3.227P" data-name="C1.3.227P">
              <polyline points="773.6 141.73 775.66 142.33 787.92 100.92 764.51 94.15 753.23 135.92 769.97 140.67" />
              <text transform="matrix(0.27, -0.99, 0.96, 0.27, 773.45, 129.48)">
                227P
              </text>
            </g>
            <g id="C1.3.226P" data-name="C1.3.226P">
              <polyline points="748.08 134.5 729.92 129.68 740.59 87.67 764.51 94.15 753.23 135.92 751.63 135.48" />
              <text transform="matrix(0.25, -0.99, 0.97, 0.26, 749.04, 122.01)">
                226P
              </text>
            </g>
            <g id="C1.3.225P" data-name="C1.3.225P">
              <polyline points="727.95 129.17 729.92 129.68 740.59 87.67 717.11 81.75 707.37 124.06 724.62 128.32" />
              <text transform="matrix(0.24, -0.99, 0.97, 0.25, 725.52, 116.99)">
                225P
              </text>
            </g>
            <g id="C1.3.224P" data-name="C1.3.224P">
              <polyline points="701.69 122.71 688.16 119.61 697.15 77.09 717.11 81.75 707.37 124.06 704.74 123.42" />
              <text transform="matrix(0.2, -1, 0.98, 0.2, 704.77, 110.52)">
                224P
              </text>
            </g>
            <g id="C1.3.223P" data-name="C1.3.223P">
              <polyline points="683.12 118.49 665.72 114.83 674.2 72.18 697.15 77.09 688.16 119.61 685.92 119.11" />
              <text transform="matrix(0.21, -1, 0.98, 0.21, 683.77, 106.51)">
                223P
              </text>
            </g>
            <g id="C1.3.222P" data-name="C1.3.222P">
              <polyline points="659.48 113.6 641.91 110.35 648.4 67.31 674.2 72.18 665.72 114.83 663.14 114.32" />
              <text transform="matrix(0.18, -1.01, 0.98, 0.18, 660.3, 101.87)">
                222P
              </text>
            </g>
            <g id="C1.3.221K" data-name="C1.3.221K">
              <polyline points="630.03 108.39 623.26 107.3 629.62 64.37 648.4 67.31 641.91 110.35 633.76 108.98" />
              <text transform="matrix(0.19, -1.01, 0.98, 0.19, 638.36, 99.51)">
                221K
              </text>
            </g>
          </g>
          <g className="map-groups-techs">
            <polygon points="769.25 211.44 766.87 210.71 783.68 215.87 800.33 164.79 771.53 155.76 769.56 162.61 758.97 199.48 745.81 195.54 743.49 203.79 761.77 209.07 743.49 203.79 745.81 195.54 758.97 199.48 769.56 162.61 781.67 166.24 771.61 203.07 769.25 211.44" />
            <polygon points="711.36 196.19 728.41 200.34 730.8 192 709.95 186.69 718.37 157.1 699.92 152.38 692.87 182.34 702.47 184.78 700.15 193.27 705.43 194.66 684.71 189.34 697.31 135.47 743.14 147.45 739.36 161.09 740.14 158.22 730.95 155.85 721.68 189.65 730.8 192 731.86 188.3 728.36 200.51 711.36 196.19" />
          </g>
          <g className="map-groups-escapes">
            <polyline points="767.5 154.49 771.53 155.76 758.97 199.48 745.81 195.54 758.17 151.71 763.62 153.33" />
            <polyline points="659.3 309.05 665.09 310.29 657.87 344.09 640.69 339.95 647.62 306.56 653.22 307.76" />
            <polyline points="751.62 334.25 757.11 336.19 744.65 370.02 729.87 364.83 741.28 330.6 746.25 332.36" />
          </g>
          <g className="map-groups-wcs">
            <polyline points="769.55 202.54 771.61 203.07 781.67 166.24 769.56 162.61 758.97 199.48 764.69 201.14" />
            <polyline points="702.47 184.78 692.72 182.3 699.92 152.38 718.37 157.1 709.95 186.69 707.89 186.16" />
          </g>
          <g className="map-groups-void">
            <path
              d="M710.52,491.19l25.66-120.14c40.57,10.06,47.32,12.3,86.44,24.82L783.83,512.24Z"
              transform="translate(-50.14 -158.86)"
            />
          </g>
        </g>
        <g id="C1.2">
          <g className="map-groups-rooms">
            <g id="C1.2.255" data-name="C1.2.255">
              <polyline points="562.44 282.26 576.34 283.74 569.78 327.96 544.69 324.17 548.75 281.02 558.27 281.82" />
              <text transform="translate(549.96 308.88) scale(0.98 1)">
                255
              </text>
            </g>
            <g id="C1.2.254" data-name="C1.2.254">
              <polyline points="541.86 280.52 548.75 281.02 544.69 324.17 512.13 321.32 513.72 278.49 536.15 280.11" />
              <text transform="translate(520.09 304.7) scale(0.98 1)">254</text>
            </g>
            <g id="C1.2.252K" data-name="C1.2.252K">
              <polyline points="537.79 243.52 538.01 241.09 496.68 238.54 495.8 264.55 535.57 267.04 537.32 248.46" />
              <text transform="translate(505.01 256.66) scale(0.98 1)">
                252K
              </text>
            </g>
            <g id="C1.2.251L" data-name="C1.2.251L">
              <polyline points="550.72 260.09 549.96 268.16 578.15 271.48 583.77 233.56 553.57 229.93 551.08 256.35" />
              <text transform="translate(554.35 253.15) scale(0.98 1)">
                251L
              </text>
            </g>
            <g id="C1.2.250L" data-name="C1.2.250L">
              <polyline points="540.14 218.54 540.39 215.86 497.53 213.21 496.68 238.54 538.01 241.09 539.82 221.97" />
              <text transform="translate(507.26 230.97) scale(0.98 1)">
                250L
              </text>
            </g>
            <g id="C1.2.249L" data-name="C1.2.249L">
              <polyline points="554.21 223.09 553.57 229.93 583.77 233.56 589.26 196.53 557.01 193.35 554.52 219.82" />
              <text transform="translate(558.51 219.11) scale(0.98 1)">
                249L
              </text>
            </g>
            <g id="C1.2.248L" data-name="C1.2.248L">
              <polyline points="535.94 191.69 498.31 190.38 497.53 213.21 540.39 215.86 542.65 191.92 539.19 191.8" />
              <text transform="translate(509.41 205.92) scale(0.98 1)">
                248L
              </text>
            </g>
            <g id="C1.2.246" data-name="C1.2.246">
              <polyline points="358.04 282.72 348.51 283.85 355.05 327.96 374.84 324.9 369.77 281.47 362.1 282.29" />
              <text transform="matrix(0.15, 1.01, -0.99, 0.16, 356.93, 296.02)">
                246
              </text>
            </g>
            <g id="C1.2.245" data-name="C1.2.245">
              <polyline points="380.34 280.71 369.77 281.47 374.84 324.9 412.91 321.29 411.08 278.49 385.55 280.33" />
              <text transform="translate(382.18 304.7) scale(0.98 1)">245</text>
            </g>
            <g id="C1.2.243K" data-name="C1.2.243K">
              <polyline points="388.65 260.57 387.04 243.52 428.22 240.67 429.05 265.13 389.27 267.12 388.96 263.82" />
              <text transform="translate(393.74 258.48) scale(0.98 1)">
                243K
              </text>
            </g>
            <g id="C1.2.242K" data-name="C1.2.242K">
              <polyline points="386.4 236.72 384.6 217.59 427.37 215.36 428.22 240.67 387.04 243.52 386.75 240.36" />
              <text transform="translate(393.74 232.58) scale(0.98 1)">
                242K
              </text>
            </g>
            <g id="C1.2.241K" data-name="C1.2.241K">
              <polyline points="372.07 238.53 374.72 268.16 346.63 271.48 341.35 235.55 371.45 231.89 371.76 235.21" />
              <text transform="translate(346.03 254.64) scale(0.98 1)">
                241K
              </text>
            </g>
            <g id="C1.2.240K" data-name="C1.2.240K">
              <polyline points="383.98 210.96 382.18 191.9 426.52 190.38 427.37 215.36 384.6 217.59 384.31 214.54" />
              <text transform="translate(393.74 207.69) scale(0.98 1)">
                240K
              </text>
            </g>
            <g id="C1.2.239K" data-name="C1.2.239K">
              <polyline points="368.61 201.79 371.45 231.89 341.35 235.55 335.57 196.56 367.82 193.33 368.23 197.76" />
              <text transform="translate(342.47 219.11) scale(0.98 1)">
                239K
              </text>
            </g>
            <g id="C1.2.237L" data-name="C1.2.237L">
              <polyline points="346.46 171.47 332.03 172.67 323.76 116.88 395.09 110.53 398.69 167.15 351.76 171.04" />
              <text transform="translate(350.74 143.6) scale(0.98 1)">
                237L
              </text>
            </g>
            <g id="C1.2.234K" data-name="C1.2.234K">
              <polyline points="581.22 115.13 601.07 116.9 592.8 172.68 561.43 170.08 567.25 113.88 577.78 114.82" />
              <text transform="translate(567.25 147.24) scale(0.98 1)">
                234K
              </text>
            </g>
            <g id="C1.2.233L" data-name="C1.2.233L">
              <polyline points="527.18 151.59 529.74 110.52 567.25 113.88 561.43 170.08 526.13 167.15 526.91 155.89" />
              <text transform="translate(533.3 145.21) scale(0.98 1)">
                233L
              </text>
            </g>
            <g id="C1.2.232P" data-name="C1.2.232P">
              <polyline points="587.64 102.26 602.9 104.55 609.28 61.48 585.44 57.85 581.06 101.35 584.25 101.79" />
              <text transform="matrix(-0.1, 1.02, -0.99, -0.11, 591.13, 69.92)">
                232P
              </text>
            </g>
            <g id="C1.2.231K" data-name="C1.2.231K">
              <polyline points="578.08 100.95 581.06 101.35 585.44 57.85 560.65 54.71 556.81 98.38 574.19 100.45" />
              <text transform="matrix(-0.07, 1.02, -1, -0.07, 565.7, 65.92)">
                231K
              </text>
            </g>
            <g id="C1.2.230P" data-name="C1.2.230P">
              <polyline points="545.02 97.17 556.81 98.38 560.65 54.71 540.73 52.67 538.23 96.55 541.26 96.82" />
              <text transform="matrix(-0.09, 1.02, -1, -0.09, 545.87, 63.33)">
                230P
              </text>
            </g>
            <g id="C1.2.229P" data-name="C1.2.229P">
              <polyline points="535.14 96.28 538.23 96.55 540.73 52.67 514.88 50.69 512.97 94.67 531.49 95.97" />
              <text transform="matrix(-0.07, 1.02, -1, -0.07, 522.93, 61.46)">
                229P
              </text>
            </g>
            <g id="C1.2.228P" data-name="C1.2.228P">
              <polyline points="509.96 94.5 512.97 94.67 514.88 50.69 488.79 49.48 488.2 93.55 506.5 94.31" />
              <text transform="matrix(-0.02, 1.02, -1, -0.02, 497.56, 58.82)">
                228P
              </text>
            </g>
            <g id="C1.2.227P" data-name="C1.2.227P">
              <polyline points="485.21 93.49 488.2 93.55 488.79 49.48 462.88 49.07 464.19 93.16 481.69 93.38" />
              <text transform="matrix(-0.01, 1.02, -1, -0.01, 471.43, 58.76)">
                227P
              </text>
            </g>
            <g id="C1.2.226P" data-name="C1.2.226P">
              <polyline points="461.11 93.16 464.19 93.16 462.88 49.07 436.83 49.45 438.73 93.49 457.42 93.17" />
              <text transform="matrix(0.02, 1.02, -1, 0.02, 445.49, 58.68)">
                226P
              </text>
            </g>
            <g id="C1.2.225P" data-name="C1.2.225P">
              <polyline points="435.71 93.58 438.73 93.49 436.83 49.45 410.93 50.63 413.57 94.56 432.05 93.7" />
              <text transform="matrix(0.08, 1.02, -1, 0.08, 418.88, 60.28)">
                225P
              </text>
            </g>
            <g id="C1.2.224P" data-name="C1.2.224P">
              <polyline points="410.63 94.74 413.57 94.56 410.93 50.63 384.53 52.63 387.87 96.42 406.42 95" />
              <text transform="matrix(0.09, 1.02, -1, 0.09, 394.76, 61.8)">
                224P
              </text>
            </g>
            <g id="C1.2.223K" data-name="C1.2.223K">
              <polyline points="374.95 97.64 387.87 96.42 384.53 52.63 364.71 54.65 369.52 98.21 371.4 98.01" />
              <text transform="matrix(0.12, 1.02, -0.99, 0.12, 371.47, 63.35)">
                223K
              </text>
            </g>
            <g id="C1.2.222P" data-name="C1.2.222P">
              <polyline points="366.6 98.53 369.52 98.21 364.71 54.65 338.7 57.95 343.91 101.31 362.93 98.94" />
              <text transform="matrix(0.11, 1.02, -0.99, 0.11, 348.16, 65.02)">
                222P
              </text>
            </g>
            <g id="C1.2.221P" data-name="C1.2.221P">
              <polyline points="341.16 101.69 343.91 101.31 338.7 57.95 315.55 61.48 322.28 104.55 337.05 102.26" />
              <text transform="matrix(0.13, 1.01, -0.99, 0.14, 324.68, 68.77)">
                221P
              </text>
            </g>
          </g>
          <g className="map-groups-techs">
            <polygon points="487.8 108.34 519.29 109.26 516.09 166.15 494.02 165.17 502.19 165.53 503.44 118.63 487.63 117.94 486.9 156.72 471.4 156.29 471.4 164.36 489.19 164.96 471.4 164.36 471.4 156.29 486.9 156.72 487.8 108.34" />
            <polygon points="406.71 109.68 410.31 166.58 434.06 165.54 428.09 165.8 427.78 157.05 417.24 157.52 415.36 126.38 434.7 125.52 435.54 156.71 456.53 156.29 456.68 164.53 438.9 165.32 456.68 164.53 456.48 152.6 456.53 156.29 446.85 156.48 446.44 121.04 456.23 120.89 456.29 123.99 455.95 108.24 406.71 109.68" />
          </g>
          <g className="map-groups-escapes">
            <polyline points="510.12 282.3 513.57 282.49 512.13 321.32 493.91 320.17 495.23 281.47 504.4 281.98" />
            <polyline points="414.47 282.31 411.25 282.49 412.91 321.29 430.91 320.17 429.6 281.47 421.33 281.93" />
            <polygon points="471.4 156.29 486.9 156.72 487.8 108.34 471.4 108.24 471.4 156.29" />
          </g>
          <g className="map-groups-wcs">
            <polyline points="427.78 157.05 417.24 157.52 415.36 126.38 434.7 125.52 435.54 156.71 433.56 156.79" />
            <polyline points="494.35 157.05 486.9 156.72 487.63 117.94 503.44 118.63 502.21 157.4 500.06 157.3" />
          </g>
          <g className="map-groups-void">
            <path
              d="M480.75,470l-4.09-120.79c41.22-1.07,32.2-1.17,71.79,0L544.36,470Z"
              transform="translate(-50.14 -158.86)"
            />
          </g>
        </g>
        <g id="C1.1">
          <g className="map-groups-rooms">
            <g id="C1.1.273" data-name="C1.1.273">
              <polyline points="47.14 192.58 52.18 202.74 20.97 216.81 7.62 192.77 40.65 179.29 45.38 188.86" />
              <text transform="matrix(0.88, -0.44, 0.43, 0.9, 23.12, 205.92)">
                273
              </text>
            </g>
            <g id="C1.1.272" data-name="C1.1.272">
              <polyline points="54.87 208.22 52.18 202.74 20.97 216.81 29.28 231.78 59.26 217.15 56.51 211.54" />
              <text transform="matrix(0.88, -0.44, 0.43, 0.9, 33.43, 224.94)">
                272
              </text>
            </g>
            <g id="C1.1.271" data-name="C1.1.271">
              <polyline points="62.08 222.87 59.26 217.15 29.28 231.78 37.33 246.28 66.82 232.51 63.8 226.37" />
              <text transform="matrix(0.88, -0.44, 0.43, 0.9, 41.91, 239.71)">
                271
              </text>
            </g>
            <g id="C1.1.270" data-name="C1.1.270">
              <polyline points="69.54 238.05 66.82 232.51 37.33 246.28 45.2 260.45 73.71 246.52 71.1 241.22" />
              <text transform="matrix(0.88, -0.44, 0.43, 0.9, 49.18, 254.87)">
                270
              </text>
            </g>
            <g id="C1.1.269" data-name="C1.1.269">
              <polyline points="76.88 252.97 73.71 246.52 45.2 260.45 53.59 275.57 81.57 262.51 78.5 256.26" />
              <text transform="matrix(0.88, -0.44, 0.43, 0.9, 56.55, 269.8)">
                269
              </text>
            </g>
            <g id="C1.1.268" data-name="C1.1.268">
              <polyline points="83.92 267.07 81.57 262.51 53.59 275.57 61.34 289.52 88.37 276.35 86.13 271.88" />
              <text transform="matrix(0.88, -0.44, 0.43, 0.9, 64.91, 283.93)">
                268
              </text>
            </g>
            <g id="C1.1.267" data-name="C1.1.267">
              <polyline points="91.34 282.39 88.37 276.35 61.34 289.52 69.78 304.73 96.02 291.89 93 285.77" />
              <text transform="matrix(0.88, -0.44, 0.43, 0.9, 72.24, 299.17)">
                267
              </text>
            </g>
            <g id="C1.1.266" data-name="C1.1.266">
              <polyline points="98.65 297.25 96.02 291.89 69.72 304.62 77.52 318.66 103.14 306.38 100.33 300.67" />
              <text transform="matrix(0.88, -0.44, 0.43, 0.9, 80.11, 313.17)">
                266
              </text>
            </g>
            <g id="C1.1.265" data-name="C1.1.265">
              <polyline points="104.87 309.89 103.14 306.38 77.47 318.57 85.56 333.15 110.08 320.49 108 316.27" />
              <text transform="matrix(0.88, -0.44, 0.43, 0.9, 88.35, 328.47)">
                265
              </text>
            </g>
            <g id="C1.1.264" data-name="C1.1.264">
              <polyline points="113.28 327.01 110.08 320.49 85.56 333.15 93.89 348.15 117.91 336.44 114.76 330.04" />
              <text transform="matrix(0.88, -0.44, 0.43, 0.9, 95.99, 343.26)">
                264
              </text>
            </g>
            <g id="C1.1.263" data-name="C1.1.263">
              <polyline points="120.6 341.9 117.91 336.44 93.83 348.04 101.44 361.75 124.69 350.2 122.17 345.09" />
              <text transform="matrix(0.88, -0.44, 0.43, 0.9, 103.11, 358.16)">
                263
              </text>
            </g>
            <g id="C1.1.262" data-name="C1.1.262">
              <polyline points="125.37 351.6 124.69 350.2 101.44 361.75 107.28 372.26 130.23 361.48 126.97 354.84" />
              <text transform="matrix(0.88, -0.44, 0.43, 0.9, 109.23, 369.94)">
                262
              </text>
            </g>
            <g id="C1.1.261" data-name="C1.1.261">
              <polyline points="133.6 359.9 107.28 372.26 119.51 394.29 148.43 381.88 146.11 376.8 148.19 375.76 139.9 356.94 135.95 358.79" />
              <text transform="translate(118.14 381.17) scale(0.98 1)">
                261
              </text>
            </g>
            <g id="C1.1.260P" data-name="C1.1.260P">
              <polygon points="132.28 338.84 148.19 375.76 146.11 376.8 148.43 381.88 177.34 371.07 163.13 327.39 132.28 338.84" />
              <text transform="translate(142.89 358.17) scale(0.98 1)">
                260P
              </text>
            </g>
            <g id="C1.1.256P" data-name="C1.1.256P">
              <polyline points="148.11 319.07 151.17 317.88 136.41 276.6 110.52 286.26 127.79 326.98 145.2 320.21" />
              <text
                className="fit-text"
                transform="translate(119.51 304.7) scale(0.98 1)"
              >
                256P
              </text>
            </g>
            <g id="C1.1.255P" data-name="C1.1.255P">
              <polyline points="157.22 315.75 176.35 309.01 162.55 267.62 136.41 276.6 151.17 317.88 153.78 316.96" />
              <text
                className="fit-text"
                transform="translate(145.4 297.41) scale(0.98 1)"
              >
                255P
              </text>
            </g>
            <g id="C1.1.254L" data-name="C1.1.254L">
              <polyline points="119.08 248.02 97.32 256.27 110.52 286.26 136.41 276.6 125.3 245.66 122.26 246.81" />
              <text
                className="fit-text"
                transform="translate(105.66 270.47) scale(0.98 1)"
              >
                254L
              </text>
            </g>
            <g id="C1.1.253L" data-name="C1.1.253L">
              <polyline points="128.21 244.71 125.3 245.66 136.41 276.6 162.55 267.62 152.29 236.82 131.24 243.71" />
              <text
                className="fit-text"
                transform="translate(132.28 261.18) scale(0.98 1)"
              >
                253L
              </text>
            </g>
            <g id="C1.1.252L" data-name="C1.1.252L">
              <polyline points="105.4 228.65 109.78 226.97 90.22 173.11 60.79 184.52 83.28 237.12 101.78 230.04" />
              <text transform="translate(73.71 207.69) scale(0.98 1)">
                252L
              </text>
            </g>
            <g id="C1.1.251L" data-name="C1.1.251L">
              <polyline points="112.33 226.09 109.78 226.97 90.22 173.11 120.37 162.87 138.31 217.04 116.18 224.74" />
              <text transform="translate(103.14 198.65) scale(0.98 1)">
                251L
              </text>
            </g>
            <g id="C1.1.250" data-name="C1.1.250">
              <polyline points="145.57 230.7 143.8 231.22 146.13 238.71 166.74 232.34 164.81 224.94 148.77 229.74" />
              <text
                className="fit-text"
                transform="matrix(0.94, -0.28, 0.28, 0.96, 150.48, 235.88)"
              >
                250
              </text>
            </g>
            <g id="C1.1.248" data-name="C1.1.248">
              <polyline points="207.39 212.22 173.07 222.01 175.32 230.07 214.3 219.35 212.17 210.86 210.41 211.36" />
              <text
                className="fit-text"
                transform="matrix(0.95, -0.24, 0.24, 0.97, 188.52, 224.19)"
              >
                248
              </text>
            </g>
            <g id="C1.1.246" data-name="C1.1.246">
              <polyline points="237.04 205.07 220.84 209.12 222.86 217.34 243.43 211.96 241.66 203.92 240.24 204.27" />
              <text
                className="fit-text"
                transform="matrix(0.95, -0.24, 0.24, 0.97, 227.12, 214.47)"
              >
                246
              </text>
            </g>
            <g id="C1.1.245K" data-name="C1.1.245K">
              <polyline points="302.31 290.58 299.59 290.99 307.17 335.16 334.71 330.86 328.16 286.69 305.24 290.14" />
              <text
                className="fit-text"
                transform="translate(307.17 314.79) scale(0.98 1)"
              >
                245K
              </text>
            </g>
            <g id="C1.1.244K" data-name="C1.1.244K">
              <polyline points="277.37 295.3 274.47 295.86 283.17 340.18 307.17 335.16 299.59 290.99 280.54 294.69" />
              <text
                className="fit-text"
                transform="translate(280.75 318.83) scale(0.98 1)"
              >
                244K
              </text>
            </g>
            <g id="C1.1.242K" data-name="C1.1.242K">
              <polyline points="287.45 277.29 288.11 280.29 326.29 274.44 322.34 247.44 282.96 254.66 286.67 273.4" />
              <text transform="translate(292.29 270.15) scale(0.98 1)">
                242K
              </text>
            </g>
            <g id="C1.1.241K" data-name="C1.1.241K">
              <polyline points="282.36 251.53 282.96 254.64 322.34 247.44 318.81 223.55 278.48 230.03 281.71 247.38" />
              <text transform="translate(288.15 243.52) scale(0.98 1)">
                241K
              </text>
            </g>
            <g id="C1.1.240K" data-name="C1.1.240K">
              <polyline points="269.27 250.22 268.58 246.45 247.12 251.22 254.82 287.27 275.22 282.76 269.94 253.91" />
              <text
                className="fit-text"
                transform="translate(251.33 270.91) scale(0.98 1)"
              >
                240K
              </text>
            </g>
            <g id="C1.1.239K" data-name="C1.1.239K">
              <polyline points="278.04 227.61 278.48 230 318.81 223.55 315.25 199.62 274.31 206.35 277.46 223.61" />
              <text transform="translate(283.09 219.11) scale(0.98 1)">
                239K
              </text>
            </g>
            <g id="C1.1.238K" data-name="C1.1.238K">
              <polyline points="262.69 214.11 261.65 208.44 238.99 213.15 247.12 251.22 268.58 246.45 263.33 217.62" />
              <text
                className="fit-text"
                transform="translate(244.21 232.98) scale(0.98 1)"
              >
                238K
              </text>
            </g>
            <g id="C1.1.235P" data-name="C1.1.235P">
              <polyline points="265.81 158.89 270.24 182.53 311.67 175.42 307.23 145.47 264.64 152.23 265.13 154.84" />
              <text transform="translate(276.26 167.03) scale(0.98 1)">
                235P
              </text>
            </g>
            <g id="C1.1.234P" data-name="C1.1.234P">
              <polyline points="248.15 131.93 247.66 129.25 226.14 133.87 239.1 188.63 257.85 184.89 248.8 135.52" />
              <text transform="matrix(0.2, 1, -0.98, 0.2, 235.8, 146.59)">
                234P
              </text>
            </g>
            <g id="C1.1.233P" data-name="C1.1.233P">
              <polyline points="263.51 126.3 260.03 126.89 264.63 152.1 307.23 145.47 303.4 119.62 266.91 125.73" />
              <text transform="translate(272.59 140.05) scale(0.98 1)">
                233P
              </text>
            </g>
            <g id="C1.1.232P" data-name="C1.1.232P">
              <line x1="293.13" y1="108.72" x2="265.25" y2="113.42" />
              <polyline points="297.42 108.01 301.57 107.3 295.21 64.37 250.31 72.24 258.76 114.51 261.3 114.09" />
              <text transform="translate(264.64 93.65) scale(0.98 1)">
                232P
              </text>
            </g>
            <g id="C1.1.231P" data-name="C1.1.231P">
              <polyline points="251.3 116 234.99 119.23 226.29 77.41 250.31 72.24 258.76 114.51 254.59 115.34" />
              <text transform="matrix(0.2, 1, -0.98, 0.2, 235.8, 84.81)">
                231P
              </text>
            </g>
            <g id="C1.1.230P" data-name="C1.1.230P">
              <polygon points="207.59 81.78 212.28 102.39 230.67 98.45 226.29 77.41 207.59 81.78" />
              <text
                className="fit-text"
                transform="matrix(0.71, 0.7, -0.69, 0.73, 212.29, 86.2)"
              >
                230P
              </text>
            </g>
            <g id="C1.1.229P" data-name="C1.1.229P">
              <polygon points="216.94 122.9 234.99 119.23 230.67 98.45 212.28 102.39 216.94 122.9" />
              <text
                className="fit-text"
                transform="matrix(0.73, 0.68, -0.67, 0.75, 216.93, 106.52)"
              >
                229P
              </text>
            </g>
            <g id="C1.1.228P" data-name="C1.1.228P">
              <polygon points="182.67 88.08 207.59 81.78 216.94 122.9 193.14 128.56 182.67 88.08" />
              <text transform="matrix(0.27, 0.99, -0.96, 0.27, 192.61, 93.62)">
                228P
              </text>
            </g>
            <g id="C1.1.227P" data-name="C1.1.227P">
              <polyline points="190.61 129.24 193.14 128.56 182.67 88.08 158.57 94.64 169.8 134.8 187.21 130.15" />
              <text transform="matrix(0.27, 0.98, -0.96, 0.28, 169.09, 100.51)">
                227P
              </text>
            </g>
            <g id="C1.1.226P" data-name="C1.1.226P">
              <polyline points="163.4 136.63 146.52 141.47 134.32 101.7 158.57 94.64 169.8 134.8 167.06 135.59" />
              <text transform="matrix(0.28, 0.98, -0.96, 0.29, 146.19, 106.54)">
                226P
              </text>
            </g>
            <g id="C1.1.225P" data-name="C1.1.225P">
              <polyline points="140.17 143.41 123.42 148.51 109.79 109.29 134.32 101.7 146.52 141.47 143.56 142.38" />
              <text transform="matrix(0.29, 0.98, -0.95, 0.3, 121.92, 113.39)">
                225P
              </text>
            </g>
            <g id="C1.1.224P" data-name="C1.1.224P">
              <polyline points="117.33 150.6 93.24 158.85 80.12 119.09 109.79 109.29 123.42 148.51 120.44 149.51" />
              <text transform="matrix(0.32, 0.97, -0.95, 0.33, 94.46, 122.92)">
                224P
              </text>
            </g>
            <g id="C1.1.223P" data-name="C1.1.223P">
              <polyline points="91.19 159.6 93.24 158.85 80.12 119.09 51.29 129.25 52.76 133.61 57.3 132.13 70.97 166.93 87.68 160.87" />
              <text transform="matrix(0.35, 0.96, -0.93, 0.35, 66.85, 132.58)">
                223P
              </text>
            </g>
            <g id="C1.1.222P" data-name="C1.1.222P">
              <polyline points="60.65 170.9 54.92 173.1 38.4 133.99 51.29 129.25 52.76 133.61 57.3 132.13 70.97 166.93 64.52 169.41" />
              <text transform="matrix(0.36, 0.95, -0.93, 0.37, 45.32, 140.05)">
                222P
              </text>
            </g>
            <g id="C1.1.221P" data-name="C1.1.221P">
              <polyline points="52.41 174.19 54.92 173.1 38.4 133.99 18.82 143.01 9.96 149.84 3.49 158.85 1.04 168.82 3.18 182.78 7.62 192.77 40.65 179.29 48.74 175.78" />
              <text transform="translate(13.8 167.03) scale(0.98 1)">221P</text>
            </g>
          </g>
          <g className="map-groups-techs">
            <polyline points="155.5 211.64 160.9 209.94 138.54 216.96 120.37 162.87 152.6 153.26 154.92 161.42 165.74 199.46 168.08 207.69 165.74 208.42 168.08 207.69 165.74 199.46 158.08 201.86 165.74 199.46 154.92 161.42 139.35 165.7 150.65 204.19 152.97 203.47" />
            <polyline points="215.67 186 212.26 186.84 204.65 158.56 223.41 153.98 230.03 182.5 220.58 184.81 230.03 182.5 231.88 190.46 218.74 193.76 239.1 188.63 226.4 133.87 181.07 145.17 185.2 160.22 184.34 157.11 193.58 154.86 202.88 189.13 193.49 191.41 195.8 199.55 212.83 195.25 195.8 199.55 192.59 188.25 193.49 191.41 215.67 186" />
          </g>
          <g className="map-groups-escapes">
            <polyline points="177.71 336.98 184.91 334.68 194.96 364.83 177.34 371.07 167.31 340.24 172.57 338.6" />
            <polyline points="268.03 311.65 260.35 313.17 266.96 344.09 283.17 340.18 277.2 309.75 271.59 310.85" />
            <polyline points="157.69 151.7 152.6 153.26 168.08 207.69 181.4 203.59 166.19 149.09 161.18 150.63" />
          </g>
          <g className="map-groups-wcs">
            <polyline points="220.58 184.81 230.03 182.5 223.41 153.98 204.65 158.56 212.26 186.84 215.67 186" />
            <polyline points="152.97 203.47 150.65 204.19 139.35 165.7 154.92 161.42 165.74 199.46 158.08 201.86" />
          </g>
          <g>
            <g className="map-groups-walls">
              <polyline points="181.33 335.86 184.91 334.68 176.35 309.01" />
              <polyline points="254.82 287.27 260.35 313.17 263.36 312.71" />
            </g>
            <g className="label">
              <text transform="matrix(0.95, -0.26, 0.25, 0.97, 177.71, 288.44)">
                ASSEMBLY
              </text>
              <text transform="matrix(0.95, -0.26, 0.25, 0.97, 200.23, 297.37)">
                HALL
              </text>
            </g>
          </g>
        </g>
      </g>
      <Stairs />
      <IconsEscapeFirstSecond />
      <IconsEscapesSecondThird />
    </MapLayout>
  );
};

export default SecondFloorAll;
