import React from "react";

const Wallpaper = () => {
  return (
    <g id="WALLPAPER">
      <path
        className="bg"
        d="M365.69,220.33l3.75,25.34L349,248.42l-3.68-25.19S238.19,236.7,85.94,293.82C61.26,303.08,41,318,56.6,349.53L169.65,553.15C206.77,537,206.77,537,245.1,523.69l-3.82-11.45,73.31-21.05L317.1,503c32.67-7.93,32.67-7.93,67.75-13.24l-3.23-21.78L402,465l3.24,21.85c37.62-5.89,37.58-5.8,75.86-7.8l-.3-9h63.61l-.31,9c37.8,2,37.76,1.83,75.87,7.8L623.16,465l20.33,3-3.23,21.78C675.34,495,675.34,495,708,503l2.51-11.76,73.31,21.05L780,523.69c38.33,13.33,38.33,13.33,75.13,30L968.2,350.1c15.88-32.14-4.35-47-29-56.28-152.24-57.12-259.4-70.59-259.4-70.59l-3.69,25.19-20.4-2.75,3.75-25.34C558.5,203.79,466.61,203.79,365.69,220.33Z"
        transform="translate(-50.14 -158.86)"
      />
      <path
        data-name="C1.3"
        className="label-huge"
        d="M811.52,216.34l-3.5-1a12.08,12.08,0,0,0-2.11-7.72,11.94,11.94,0,0,0-2.91-2.94,12.66,12.66,0,0,0-4-1.88,12.47,12.47,0,0,0-7.11,0,13.72,13.72,0,0,0-6.3,4.24,21.16,21.16,0,0,0-4.26,8.49,21.56,21.56,0,0,0-.75,9.53,14.07,14.07,0,0,0,3.2,6.91,12.53,12.53,0,0,0,6.06,3.75,12.84,12.84,0,0,0,4.38.49,12.27,12.27,0,0,0,4-.95,11.7,11.7,0,0,0,3.35-2.24,11.46,11.46,0,0,0,2.44-3.27l3.49,1a16.07,16.07,0,0,1-3.12,4.45,15.26,15.26,0,0,1-4.34,3.07,15.1,15.1,0,0,1-5.25,1.39,16.23,16.23,0,0,1-5.86-.63,15.87,15.87,0,0,1-7.84-4.85,17.26,17.26,0,0,1-3.93-8.54,28.17,28.17,0,0,1,5.82-21,16.86,16.86,0,0,1,7.75-5.2,15.66,15.66,0,0,1,9.2,0,16.37,16.37,0,0,1,5.33,2.55A15,15,0,0,1,809,206a15.53,15.53,0,0,1,2.12,4.91A16,16,0,0,1,811.52,216.34ZM834,209.73l-10.7,38.52-3.48-1,9.68-34.83-.22-.07-10.59,3.47,1-3.65,10.68-3.48ZM835.27,252a2.75,2.75,0,0,1-1.68-1.33,2.81,2.81,0,0,1-.25-2.14,2.77,2.77,0,0,1,3.43-2,2.68,2.68,0,0,1,1.68,1.33,2.85,2.85,0,0,1-1.74,4.11A2.61,2.61,0,0,1,835.27,252Zm22.06,6.52a15.32,15.32,0,0,1-5.93-3.06,11.33,11.33,0,0,1-3.39-4.84,9.83,9.83,0,0,1-.25-5.77l3.52,1a6.71,6.71,0,0,0,.26,4.05A8.29,8.29,0,0,0,854,253.2a11.16,11.16,0,0,0,4.15,2.11,10.76,10.76,0,0,0,4.92.33,8,8,0,0,0,3.92-1.83,7.31,7.31,0,0,0,2.25-3.62,7.69,7.69,0,0,0,0-4.46,8.11,8.11,0,0,0-2.6-3.74,13,13,0,0,0-4.93-2.47l-2.23-.63.88-3.16,2.23.63a10.45,10.45,0,0,0,4.62.34,7.61,7.61,0,0,0,3.73-1.72,7.08,7.08,0,0,0,2.18-3.58,7.65,7.65,0,0,0,.09-4,7,7,0,0,0-2-3.3A8.74,8.74,0,0,0,867.5,222a9.75,9.75,0,0,0-4.3-.29,7.79,7.79,0,0,0-3.65,1.6,6.92,6.92,0,0,0-2.23,3.41l-3.37-1a9.83,9.83,0,0,1,3.11-4.82,11,11,0,0,1,5.17-2.38,13.29,13.29,0,0,1,6.17.35,12.1,12.1,0,0,1,5.43,3,10.37,10.37,0,0,1,2.76,4.78,10.19,10.19,0,0,1,0,5.45,9.15,9.15,0,0,1-9,7l-.07.24a10,10,0,0,1,4.82,4.9,9.71,9.71,0,0,1,.38,6.79,10.24,10.24,0,0,1-3.14,5.09,11.63,11.63,0,0,1-5.46,2.63A14.27,14.27,0,0,1,857.33,258.48Z"
        transform="translate(-50.14 -158.86)"
      />
      <path
        data-name="C1.2"
        className="label-huge"
        d="M496.72,171.67h-3.6a11.16,11.16,0,0,0-1.46-3.72,11.42,11.42,0,0,0-2.61-3,12,12,0,0,0-3.57-2,12.82,12.82,0,0,0-4.31-.71,12.49,12.49,0,0,0-6.79,1.94,13.69,13.69,0,0,0-4.89,5.69,24.09,24.09,0,0,0,0,18.37,13.66,13.66,0,0,0,4.89,5.68,12.56,12.56,0,0,0,6.79,1.92,12.82,12.82,0,0,0,4.31-.71,12,12,0,0,0,3.57-2,11.75,11.75,0,0,0,4.07-6.75h3.6a15.78,15.78,0,0,1-1.8,5.05,15.18,15.18,0,0,1-8,6.78,16.22,16.22,0,0,1-5.77,1,15.81,15.81,0,0,1-8.78-2.47,16.81,16.81,0,0,1-6-7,27.37,27.37,0,0,1,0-21.36,16.69,16.69,0,0,1,6-7,15.74,15.74,0,0,1,8.78-2.49,16.44,16.44,0,0,1,5.77,1,14.47,14.47,0,0,1,4.64,2.73,15,15,0,0,1,3.34,4.06A15.61,15.61,0,0,1,496.72,171.67Zm19.7-12.28v39.28h-3.59V163.15h-.23l-9.2,6.12v-3.72l9.28-6.16ZM528.88,199a2.75,2.75,0,0,1-2.76-2.76,2.63,2.63,0,0,1,.81-1.95,2.76,2.76,0,0,1,3.91,0,2.63,2.63,0,0,1,.81,1.95,2.57,2.57,0,0,1-.39,1.38,2.84,2.84,0,0,1-1,1A2.69,2.69,0,0,1,528.88,199Zm11.65-.28V196l12.83-14.42q2.5-2.84,4-4.85a16.64,16.64,0,0,0,2.23-3.72,9.22,9.22,0,0,0,.71-3.55,7.16,7.16,0,0,0-3.91-6.54,8.82,8.82,0,0,0-4.11-.94,8.46,8.46,0,0,0-4.24,1,7.23,7.23,0,0,0-2.79,2.83,8.41,8.41,0,0,0-1,4.15h-3.43a11.06,11.06,0,0,1,1.49-5.78,10.91,10.91,0,0,1,4.12-4,12.32,12.32,0,0,1,5.95-1.43,11.76,11.76,0,0,1,5.83,1.42,10.5,10.5,0,0,1,4,3.81,10.27,10.27,0,0,1,1.45,5.41,11.86,11.86,0,0,1-.76,4.2,19.16,19.16,0,0,1-2.59,4.52q-1.85,2.5-5.1,6.1l-9.74,10.87v.25h19.42v3.23Z"
        transform="translate(-50.14 -158.86)"
      />
      <path
        data-name="C1.1"
        className="label-huge"
        d="M178.57,225.75l-3.5,1a11.25,11.25,0,0,0-2.43-3.22,11.64,11.64,0,0,0-3.35-2.22,12,12,0,0,0-4-1,12.82,12.82,0,0,0-4.38.48,12.64,12.64,0,0,0-6.07,3.73,13.77,13.77,0,0,0-3.2,6.86,20.79,20.79,0,0,0,.72,9.4,21.12,21.12,0,0,0,4.27,8.45,13.74,13.74,0,0,0,6.29,4.18,12.55,12.55,0,0,0,7.11,0,12.91,12.91,0,0,0,4-1.86,12.19,12.19,0,0,0,2.93-2.89,11.8,11.8,0,0,0,2.12-7.66l3.5-1a15.94,15.94,0,0,1-.38,5.39,15.26,15.26,0,0,1-5.91,8.76,16.58,16.58,0,0,1-5.34,2.52,16,16,0,0,1-9.21,0,17.08,17.08,0,0,1-7.75-5.18,27.6,27.6,0,0,1-5.8-20.75,16.94,16.94,0,0,1,3.95-8.44,15.83,15.83,0,0,1,7.85-4.8,16.55,16.55,0,0,1,5.88-.62,14.67,14.67,0,0,1,5.24,1.38,15.25,15.25,0,0,1,4.35,3.05A15.45,15.45,0,0,1,178.57,225.75Zm15.8-17.27L205,246.63l-3.49,1-9.64-34.5-.22.06-7.28,8.45-1-3.62,7.34-8.5Zm22.85,35a2.72,2.72,0,0,1-2.12-.25,2.75,2.75,0,0,1-1.31-1.68,2.77,2.77,0,0,1,1.93-3.43,2.78,2.78,0,0,1,3.44,1.93,2.85,2.85,0,0,1-.7,2.7A2.63,2.63,0,0,1,217.22,243.52Zm7.89-43.64L235.77,238l-3.48,1-9.64-34.5-.23.06L215.14,213l-1-3.61,7.35-8.5Z"
        transform="translate(-50.14 -158.86)"
      />
    </g>
  );
};

export default Wallpaper;
