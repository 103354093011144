const ICONS_C1_1 = ({ isFirst }) => {
  return (
    <g id="ICONS_MINIMAP_COMMON">
      <g className="map-groups-wcs-icon">
        <path
          d="M273.16,688.9l-13-.15a1.5,1.5,0,0,0-1.52,1.48l-.16,13a1.5,1.5,0,0,0,1.48,1.52l13,.15a1.5,1.5,0,0,0,1.52-1.48l.16-13A1.51,1.51,0,0,0,273.16,688.9Z"
          transform="translate(-72.19 -567.47)"
        />
        <path
          d="M270.11,693.37l-.08,7-7-.09.08-7Zm0-1-7-.09a1,1,0,0,0-1,1l-.08,7a1,1,0,0,0,1,1l7,.09a1,1,0,0,0,1-1l.08-7A1,1,0,0,0,270.12,692.37Zm-4.59,7.44,0-2h.5v-1.25a1,1,0,0,0-1-1h-.5a1,1,0,0,0-1,1v1.25h.5l0,2Zm-.69-4.76a.61.61,0,0,0,.63-.61.63.63,0,0,0-.61-.64.64.64,0,0,0-.64.62A.63.63,0,0,0,264.84,695.05Zm4.74,1.31-1.23-2-1.27,2Zm-2.52,1,1.23,2,1.27-2Z"
          transform="translate(-72.19 -567.47)"
        />
      </g>

      <g className="map-groups-escapes-icon">
        <path
          d="M257.08,865.77l-13-.16a1.5,1.5,0,0,0-1.52,1.48l-.16,13a1.5,1.5,0,0,0,1.48,1.52l13,.16a1.51,1.51,0,0,0,1.52-1.49l.16-13A1.51,1.51,0,0,0,257.08,865.77Z"
          transform="translate(-72.19 -567.47)"
        />
        <path
          d="M251.8,870.33a1,1,0,1,0,0-2,1,1,0,0,0,0,2Zm-1.88,6.93.53-2.2,1,1,0,3h1l0-3.75-1-1,.31-1.5a3.67,3.67,0,0,0,2.74,1.28v-1a2.45,2.45,0,0,1-2.13-1.22l-.49-.81a1,1,0,0,0-.85-.51c-.15,0-.25,0-.4,0L248,871.68l0,2.35,1,0,0-1.7.9-.34-.84,4-2.45-.53-.21,1Z"
          transform="translate(-72.19 -567.47)"
        />
      </g>

      <g className="map-groups-escapes-icon">
        <path
          d="M349.22,839.78l-13-.15a1.5,1.5,0,0,0-1.52,1.48l-.16,13a1.51,1.51,0,0,0,1.49,1.52l13,.15a1.5,1.5,0,0,0,1.52-1.48l.16-13A1.5,1.5,0,0,0,349.22,839.78Z"
          transform="translate(-72.19 -567.47)"
        />
        <path
          d="M343.94,844.35a1,1,0,1,0,0-2,1,1,0,0,0-1,1A1,1,0,0,0,343.94,844.35Zm-1.89,6.92.53-2.19,1,1,0,3,1,0,.05-3.75-1-1,.32-1.49a3.66,3.66,0,0,0,2.73,1.28v-1a2.44,2.44,0,0,1-2.13-1.23l-.49-.8a1,1,0,0,0-.85-.51c-.15,0-.25,0-.4,0l-2.61,1.07,0,2.35h1l0-1.7.91-.34-.85,4-2.45-.52-.21,1Z"
          transform="translate(-72.19 -567.47)"
        />
      </g>

      <g className="map-groups-wcs-icon">
        <path
          d="M296.4,689.69l-13,0a1.51,1.51,0,0,0-1.5,1.51l0,13a1.5,1.5,0,0,0,1.51,1.49l13,0a1.49,1.49,0,0,0,1.49-1.5l0-13A1.5,1.5,0,0,0,296.4,689.69Z"
          transform="translate(-72.19 -567.47)"
        />
        <path
          d="M290.94,702.71v-3h1.5l-1.28-3.81a1,1,0,0,0-1-.68h-.06a1,1,0,0,0-.94.69l-1.26,3.82h1.5v3Zm-.78-8a1,1,0,1,0-1-1A1,1,0,0,0,290.16,694.71Z"
          transform="translate(-72.19 -567.47)"
        />
      </g>

      {!isFirst && (
        <g className="map-groups-escapes-icon">
          <path
            d="M244,677.46l-13-.16a1.5,1.5,0,0,0-1.52,1.48l-.16,13a1.5,1.5,0,0,0,1.48,1.52l13,.15A1.5,1.5,0,0,0,245.3,692l.16-13A1.5,1.5,0,0,0,244,677.46Z"
            transform="translate(-72.19 -567.47)"
          />
          <path
            d="M238.69,682a1,1,0,0,0,1-1,1,1,0,1,0-1,1ZM236.81,689l.53-2.2,1,1,0,3,1,0,0-3.75-1-1,.32-1.49a3.63,3.63,0,0,0,2.73,1.28v-1a2.44,2.44,0,0,1-2.13-1.23l-.49-.8a1,1,0,0,0-.85-.51c-.15,0-.25,0-.4,0l-2.61,1.07,0,2.35h1l0-1.7.9-.33-.84,4-2.45-.53-.21,1Z"
            transform="translate(-72.19 -567.47)"
          />
        </g>
      )}

      <g className="map-groups-wcs-icon">
        <path
          d="M233.08,708.05l-13-.16a1.5,1.5,0,0,0-1.52,1.48l-.16,13a1.5,1.5,0,0,0,1.48,1.52l13,.16a1.5,1.5,0,0,0,1.52-1.48l.16-13A1.51,1.51,0,0,0,233.08,708.05Z"
          transform="translate(-72.19 -567.47)"
        />
        <path
          d="M225.68,721l0-3.75H225l0-2.75a1,1,0,0,1,1-1l1.5,0a1,1,0,0,1,1,1l0,2.75h-.75l0,3.75Zm1.1-8a1,1,0,0,0,1-1,1,1,0,0,0-1-1,1,1,0,0,0-1,1A1,1,0,0,0,226.78,713Z"
          transform="translate(-72.19 -567.47)"
        />
      </g>
    </g>
  );
};

export default ICONS_C1_1;
