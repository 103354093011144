import React from "react";

const IconsEscapeFirstSecond = () => {
  return (
    <g id="ICONS_ESCAPE_FIRST_SECOND">
      <g className="map-groups-escapes-icon">
        <path
          d="M709.7,475.43l-13.09-.2a1.52,1.52,0,0,0-1.53,1.5L694.87,490a1.54,1.54,0,0,0,1.49,1.56l13.09.19a1.51,1.51,0,0,0,1.54-1.5l.2-13.24A1.51,1.51,0,0,0,709.7,475.43Z"
          transform="translate(-50.14 -158.86)"
        />
        <path
          d="M704.37,480.06a1,1,0,1,0-1-1A1,1,0,0,0,704.37,480.06Zm-1.92,7.05.54-2.23,1,1,0,3.06h1l.06-3.82-1-1,.33-1.53a3.66,3.66,0,0,0,2.75,1.32l0-1a2.46,2.46,0,0,1-2.15-1.25l-.49-.83a1.08,1.08,0,0,0-.85-.52c-.15,0-.25.05-.4.05l-2.64,1.08,0,2.39,1,0,0-1.74.91-.34-.87,4.11-2.46-.54-.22,1Z"
          transform="translate(-50.14 -158.86)"
        />
      </g>
      <g className="map-groups-escapes-icon">
        <path
          d="M800.47,501.19l-13.09-.2a1.52,1.52,0,0,0-1.53,1.51l-.2,13.24a1.51,1.51,0,0,0,1.48,1.55l13.1.2a1.52,1.52,0,0,0,1.53-1.51l.2-13.24A1.53,1.53,0,0,0,800.47,501.19Z"
          transform="translate(-50.14 -158.86)"
        />
        <path
          d="M795.14,505.82a1,1,0,1,0-1-1A1,1,0,0,0,795.14,505.82Zm-1.92,7.05.54-2.23,1,1,0,3.05,1,0,.06-3.82-1-1,.32-1.52a3.65,3.65,0,0,0,2.75,1.31l0-1a2.48,2.48,0,0,1-2.15-1.26l-.49-.82a1,1,0,0,0-.85-.52c-.15,0-.25,0-.4,0l-2.64,1.08,0,2.4h1l0-1.73.91-.34-.87,4.11-2.46-.55-.22,1Z"
          transform="translate(-50.14 -158.86)"
        />
      </g>
      <g className="map-groups-escapes-icon">
        <path
          d="M560.7,451.17H547.07a1.55,1.55,0,0,0-1.58,1.52v13.18a1.55,1.55,0,0,0,1.58,1.52H560.7a1.54,1.54,0,0,0,1.57-1.52V452.69A1.54,1.54,0,0,0,560.7,451.17Z"
          transform="translate(-50.14 -158.86)"
        />
        <path
          d="M555.22,455.86a1,1,0,1,0-1.05-1A1,1,0,0,0,555.22,455.86Zm-1.89,7,.53-2.23,1.1,1v3h1v-3.8l-1.1-1,.31-1.52a3.9,3.9,0,0,0,2.89,1.27v-1a2.61,2.61,0,0,1-2.26-1.22l-.52-.81a1.09,1.09,0,0,0-.9-.51c-.15,0-.26.05-.42.05l-2.72,1.12v2.38h1.05v-1.72l.94-.36-.84,4.11-2.57-.51-.21,1Z"
          transform="translate(-50.14 -158.86)"
        />
      </g>
      <g className="map-groups-escapes-icon">
        <path
          d="M478,451.17H464.41a1.55,1.55,0,0,0-1.58,1.52v13.18a1.55,1.55,0,0,0,1.58,1.52H478a1.55,1.55,0,0,0,1.58-1.52V452.69A1.55,1.55,0,0,0,478,451.17Z"
          transform="translate(-50.14 -158.86)"
        />
        <path
          d="M472.56,455.86a1,1,0,1,0-1.05-1A1,1,0,0,0,472.56,455.86Zm-1.89,7.05.53-2.23,1.1,1v3h1.05v-3.8l-1.1-1,.31-1.53a3.9,3.9,0,0,0,2.89,1.27v-1a2.61,2.61,0,0,1-2.26-1.22l-.52-.81a1.11,1.11,0,0,0-.9-.51c-.15,0-.26.05-.42.05l-2.72,1.12v2.38h1.05v-1.72l.94-.36-.84,4.11-2.57-.51-.21,1Z"
          transform="translate(-50.14 -158.86)"
        />
      </g>
      <g className="map-groups-escapes-icon">
        <path
          d="M328.46,477.71H315.35a1.52,1.52,0,0,0-1.52,1.51v13.11a1.52,1.52,0,0,0,1.52,1.52h13.11a1.52,1.52,0,0,0,1.51-1.52V479.22A1.52,1.52,0,0,0,328.46,477.71Z"
          transform="translate(-50.14 -158.86)"
        />
        <path
          d="M323.19,482.37a1,1,0,1,0-1-1A1,1,0,0,0,323.19,482.37Zm-1.82,7,.51-2.22,1.06,1v3h1v-3.78l-1.05-1,.3-1.51a3.7,3.7,0,0,0,2.77,1.26v-1a2.5,2.5,0,0,1-2.17-1.21l-.5-.81a1,1,0,0,0-.86-.5c-.15,0-.25.05-.4.05l-2.62,1.11v2.37h1v-1.72l.91-.35-.81,4.08-2.47-.5-.2,1Z"
          transform="translate(-50.14 -158.86)"
        />
      </g>
      <g className="map-groups-escapes-icon">
        <path
          d="M237.26,503.6H224.15a1.52,1.52,0,0,0-1.52,1.51v13.12a1.52,1.52,0,0,0,1.52,1.51h13.11a1.52,1.52,0,0,0,1.51-1.51V505.11A1.52,1.52,0,0,0,237.26,503.6Z"
          transform="translate(-50.14 -158.86)"
        />
        <path
          d="M232,508.27a1,1,0,0,0,0-2,1,1,0,0,0,0,2Zm-1.82,7,.51-2.22,1.06,1v3h1v-3.78l-1.06-1,.31-1.51a3.66,3.66,0,0,0,2.77,1.26v-1a2.48,2.48,0,0,1-2.17-1.21l-.5-.81a1,1,0,0,0-.86-.5c-.15,0-.25.05-.4.05l-2.62,1.11v2.37h1v-1.72l.91-.35-.81,4.09-2.47-.51-.2,1Z"
          transform="translate(-50.14 -158.86)"
        />
      </g>
    </g>
  );
};

export default IconsEscapeFirstSecond;
