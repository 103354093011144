import IconsEscapeFirstSecond from "../general/map/IconsEscapesFirstSecond";
import Stairs from "../general/map/Stairs";
import MapLayout from "../layout/MapLayout";

const FirstFloorAll = () => {
  return (
    <MapLayout>
      <g id="FIRST_FLOOR_IOS">
        <g id="C1.3">
          <g>
            <g className="map-groups-gym">
              <polygon points="624.87 198.43 610.14 195.79 590.12 330.86 639.28 339.62 648.03 304.1 666.84 308.56 660.38 332.34 733.69 353.39 772.05 238.29 692.37 214.66 677.26 210.55 624.87 198.43" />
              <polygon points="620.41 219.39 618.27 229.32 628.46 231.48 630.5 221.47 620.41 219.39" />
              <polyline points="628.78 311.12 631.2 299.78 648.03 304.1 639.28 339.62 623.42 336.2 628.06 314.45" />
              <g className="map-groups-gym-pole">
                <polygon points="725.79 345.5 757.17 243.52 702.35 227.36 671.23 329.52 709.71 340.79 725.79 345.5" />
                <polygon points="711.95 317.51 698.87 313.79 687.78 334.37 709.71 340.79 711.95 317.51" />
                <polygon points="720.66 323.96 687.75 314.56 694.55 292.32 707.88 248.68 740.65 258.42 720.66 323.96" />
                <polygon points="718.78 232.2 716.73 255.06 729.91 258.99 740.66 238.65 718.78 232.2" />
                <path
                  d="M756.74,387.47l-2.32,8.12h0c-4.61,29.17,34.78,39.87,46,13.06h0l2.31-7.62Z"
                  transform="translate(-50.14 -158.86)"
                />
                <path
                  d="M749,472.65h0c3.77-8.12,15-5.49,13.08,3.72h0"
                  transform="translate(-50.14 -158.86)"
                />
                <path d="M749,472.65" transform="translate(-50.14 -158.86)" />
                <path
                  d="M748.84,475.54a7.87,7.87,0,0,1,0-1.38"
                  transform="translate(-50.14 -158.86)"
                />
                <path
                  d="M749.68,478.2a6.73,6.73,0,0,1-.57-1.31"
                  transform="translate(-50.14 -158.86)"
                />
                <path
                  d="M751.62,480.22a6.26,6.26,0,0,1-1.19-1"
                  transform="translate(-50.14 -158.86)"
                />
                <path
                  d="M754.29,481.17a6.64,6.64,0,0,1-1.4-.33"
                  transform="translate(-50.14 -158.86)"
                />
                <path
                  d="M757.27,480.88a7.82,7.82,0,0,1-1.57.32"
                  transform="translate(-50.14 -158.86)"
                />
                <path
                  d="M760,479.32a7.57,7.57,0,0,1-1.15.85"
                  transform="translate(-50.14 -158.86)"
                />
                <path
                  d="M761.46,477.53a8.57,8.57,0,0,1-.69,1"
                  transform="translate(-50.14 -158.86)"
                />
                <path
                  d="M762.09,476.37"
                  transform="translate(-50.14 -158.86)"
                />
                <path
                  d="M780.05,417.85C776.28,426,765,423.2,766.89,414"
                  transform="translate(-50.14 -158.86)"
                />
                <path
                  d="M780.15,414.83a10.25,10.25,0,0,1,0,1.37"
                  transform="translate(-50.14 -158.86)"
                />
                <path
                  d="M779.3,412.16a6.73,6.73,0,0,1,.57,1.31"
                  transform="translate(-50.14 -158.86)"
                />
                <path
                  d="M777.36,410.14a5.69,5.69,0,0,1,1.19,1"
                  transform="translate(-50.14 -158.86)"
                />
                <path
                  d="M774.7,409.19a6.55,6.55,0,0,1,1.39.33"
                  transform="translate(-50.14 -158.86)"
                />
                <path
                  d="M771.71,409.49a6.74,6.74,0,0,1,1.57-.32"
                  transform="translate(-50.14 -158.86)"
                />
                <path
                  d="M769,411.05a7.17,7.17,0,0,1,1.15-.86"
                  transform="translate(-50.14 -158.86)"
                />
                <path
                  d="M767.52,412.84a9.85,9.85,0,0,1,.7-1"
                  transform="translate(-50.14 -158.86)"
                />
                <path
                  d="M771.74,503.22l2.32-8.12h0c4.62-29.17-34.77-39.87-46-13.06h0l-2.3,7.61Z"
                  transform="translate(-50.14 -158.86)"
                />
                <line x1="686.82" y1="278.35" x2="741.55" y2="294.26" />
                <line x1="701.24" y1="270.4" x2="734.01" y2="280.17" />
                <line x1="694.55" y1="292.32" x2="727.34" y2="302.06" />
                <path
                  d="M757.75,443.25h0c-1.77,9.12,10.51,11.95,13.61,4h0"
                  transform="translate(-50.14 -158.86)"
                />
                <path
                  d="M771.39,447.23c2.29-9.47-10.55-12.6-13.62-4"
                  transform="translate(-50.14 -158.86)"
                />
                <path
                  d="M753.73,442.08h0c-3.05,14.78,15.87,20.25,21.69,6.31h0"
                  transform="translate(-50.14 -158.86)"
                />
                <path
                  d="M775.4,448.46c3.29-15.36-16-20.37-21.69-6.31"
                  transform="translate(-50.14 -158.86)"
                />
              </g>
              <line x1="677.99" y1="267.55" x2="692.5" y2="214.19" />
              <line x1="666.5" y1="260.68" x2="676.23" y2="262.71" />
              <line x1="666.84" y1="308.57" x2="676.23" y2="274.02" />
            </g>
            <g className="map-groups-rooms">
              <g>
                <polyline points="770.27 229.92 768.21 237.13 772.05 238.29 738.31 339.5 751.29 343.78 742.51 369.24 805 394.87 868.67 280.19 791.03 245.77 799.28 223.66 775.13 215.39 773.89 219.94" />
                <g>
                  <text transform="matrix(0.9, 0.39, -0.38, 0.92, 778.55, 301.47)">
                    DINING
                  </text>
                  <text transform="matrix(0.9, 0.39, -0.38, 0.92, 777.23, 318.43)">
                    HALL
                  </text>
                </g>
              </g>
            </g>
            <g className="map-groups-techs">
              <polyline points="764.85 138.77 761.6 149.23 782.04 155.88 799.31 104.37 768.78 95.35 764.33 109.13 773.23 111.82 766.01 135.05" />
              <polyline points="668.18 119.34 668.83 116.34 623.18 107.85 613.11 175.75 685.64 190.96 694.99 154.56 661.95 147.72 667.65 121.73" />
              <polygon points="799.31 104.37 782.03 155.89 804.22 163.11 784.77 218.69 799.28 223.66 791.02 245.77 868.67 280.19 918.05 191.24 921.68 182.39 923.69 172.88 922.77 163.16 918.14 153.67 909.89 145.64 900.72 140.05 888.15 134.64 839.72 117.39 799.31 104.37" />
            </g>
          </g>
          <g className="map-groups-escapes">
            <polygon points="738.31 339.5 751.29 343.78 742.51 369.24 729.87 364.83 738.31 339.5" />
            <polygon points="648.03 304.1 666.6 308.51 657.87 344.09 639.28 339.62 648.03 304.1" />
            <polygon points="775.22 153.67 761.18 201.25 747.17 197.01 744.22 206.78 758.84 211.17 744.22 206.78 761.6 149.23 775.22 153.67" />
          </g>
          <g className="map-groups-techs">
            <polyline points="706.69 196.7 685.64 190.96 700.55 132.49 746.68 144.96 743.12 157.18 742.25 160.22 743.12 157.18 733.9 154.63 723.52 190.58 732.49 193.5 734.07 188.52 732.49 193.5 729.52 202.96 712.44 198.27" />
            <polyline points="773.94 203.06 784.46 165.94 772.69 162.1 775.22 153.67 804.22 163.1 785.06 218.69 770.26 213.77" />
          </g>
          <g className="map-groups-wcs">
            <polyline points="704.4 185.87 694.45 183.22 702.7 150.62 721.03 155.42 711.83 187.51 709.48 186.87" />
            <polyline points="764.82 202.36 761.18 201.25 772.69 162.1 784.46 165.94 773.46 204.78 771.51 204.21" />
          </g>
          <g className="map-groups-rooms">
            <g id="C1.3.188" data-name="C1.3.188">
              <polyline
                id="_3.188"
                data-name="3.188"
                points="624.59 200.57 625.03 198.46 677.26 210.55 671.88 235.32 644.74 229.72 647.35 217.34 644.74 229.72 629.43 226.57 630.5 221.47 633.41 207.97 648.78 211.44 633.41 207.97 630.5 221.47 620.41 219.39 623.41 205.87 628.13 206.88 623.41 205.87 623.98 203.32"
              />
              <text transform="translate(640.66 224.37) scale(0.98 1)">
                188
              </text>
            </g>
            <g id="C1.3.187" data-name="C1.3.187">
              <polyline points="614.76 247.78 614.22 250.61 666.5 260.68 671.88 235.32 644.74 229.72 642.32 241.36 644.74 229.72 629.43 226.57 625.81 244.74 641.25 247.83 621.06 243.78 625.81 244.74 628.46 231.48 618.27 229.32 615.25 245.19" />
              <text transform="translate(635.72 247.21) scale(0.98 1)">
                187
              </text>
            </g>
            <g id="C1.3.168" data-name="C1.3.168">
              <polygon points="793.88 193.33 808.74 198.35 799.28 223.66 784.77 218.69 793.88 193.33" />
              <text transform="matrix(0.33, -0.96, 0.94, 0.33, 797.1, 218.96)">
                168
              </text>
            </g>
            <g id="C1.3.130" data-name="C1.3.130">
              <polyline points="732.02 110.78 731.25 113.61 718.82 110.2 725.76 83.87 738.46 87.11 732.91 107.51" />
              <text
                className="fit-text"
                transform="matrix(0.26, -0.99, 0.96, 0.26, 729.87, 109.72)"
              >
                130
              </text>
            </g>
            <g id="C1.3.129" data-name="C1.3.129">
              <polyline points="708.57 103.82 707.73 107.23 718.82 110.2 725.76 83.87 714.19 81.05 709.57 99.79" />
              <text
                className="fit-text"
                transform="matrix(0.25, -0.99, 0.97, 0.26, 718.18, 106.16)"
              >
                129
              </text>
            </g>
            <g id="C1.3.128" data-name="C1.3.128">
              <polyline points="708.75 121.73 707.81 125.61 726.65 130.98 731.25 113.61 712.11 108.4 709.79 117.48" />
              <text
                className="fit-text"
                transform="translate(711.38 123.34) scale(0.98 1)"
              >
                128
              </text>
            </g>
            <g id="C1.3.126" data-name="C1.3.126">
              <polyline points="671.5 103.62 668.83 116.34 623.18 107.85 625.93 89.56 629.62 64.37 677.93 72.94 672.26 100.01" />
              <g>
                <path
                  d="M710.89,249.21a.84.84,0,0,0,.84-.83c0-.47-.34-.78-.84-1a1.23,1.23,0,0,1-.71-.6,1.23,1.23,0,0,0-.95-.68h-.61v-.53c-3.32,1.25-2.25,5.71-2.25,5.71H707v.44h-.2a.27.27,0,0,0-.27.25v0a.26.26,0,0,0,.16.23,1.08,1.08,0,0,0,0,1h-.16a.27.27,0,0,0-.27.25v.56a.26.26,0,0,0,.25.25v.49h4.1v-.5a.24.24,0,0,0,.21-.24v-.56a.23.23,0,0,0-.22-.25h-.14a1.06,1.06,0,0,0,0-1,.24.24,0,0,0,.14-.23v0a.23.23,0,0,0-.23-.25h-.24v-.44H711a5,5,0,0,0-2.06-2.57h1.25A.84.84,0,0,0,710.89,249.21Z"
                  transform="translate(-50.14 -158.86)"
                />
              </g>
              <text transform="translate(635.72 95.62) scale(0.98 1)">126</text>
            </g>
            <g id="C1.3.125" data-name="C1.3.125">
              <polyline points="675.26 117.82 668.83 116.34 677.93 72.94 714.19 81.05 707.73 107.23 712.11 108.4 707.81 125.61 678.68 118.6" />
              <text transform="translate(680.02 102.35) scale(0.98 1)">
                125
              </text>
            </g>
            <g id="C1.3.121" data-name="C1.3.121">
              <polyline points="676.67 127.16 700.55 132.49 694.99 154.56 661.95 147.72 666.94 124.98 673.43 126.44" />
              <text transform="translate(671.23 144.56) scale(0.98 1)">
                121
              </text>
            </g>
          </g>
        </g>
        <g id="C1.2">
          <g className="map-groups-rooms">
            <g id="C1.2.139" data-name="C1.2.139">
              <polyline points="541.73 217.99 541.53 220.4 585.14 224.37 589.69 193.64 544.04 188.67 542.02 214.26" />
              <text transform="translate(554.42 212.57) scale(0.98 1)">
                139
              </text>
            </g>
            <g id="C1.2.138L" data-name="C1.2.138L">
              <polyline points="539.35 247.8 541.53 220.4 585.14 224.37 580.31 256.9 538.95 252.81 539.07 251.32" />
              <text transform="translate(547.45 243.63) scale(0.98 1)">
                138L
              </text>
            </g>
            <g id="C1.2.137P" data-name="C1.2.137P">
              <polyline points="536.4 284.68 536.32 285.69 575.34 290.43 580.31 256.9 538.95 252.81 536.67 281.32" />
              <text transform="translate(544.39 276.58) scale(0.98 1)">
                137P
              </text>
            </g>
            <g id="C1.2.136" data-name="C1.2.136">
              <polyline points="536.09 288.59 536.32 285.69 575.34 290.43 572.65 308.6 534.89 303.56 535.81 292.03" />
              <text transform="translate(544.39 302.25) scale(0.98 1)">
                136
              </text>
            </g>
            <g id="C1.2.135" data-name="C1.2.135">
              <polyline points="534.45 309.03 533.36 322.71 569.78 327.96 572.65 308.6 534.89 303.56 534.7 306" />
              <text transform="translate(541.53 320.17) scale(0.98 1)">
                135
              </text>
            </g>
            <g id="C1.2.133" data-name="C1.2.133">
              <polyline points="495.25 281.32 495.13 284.69 517.74 285.69 519.11 264.86 495.85 263.8 495.38 277.69" />
              <text transform="translate(496.45 279.19) scale(0.98 1)">
                133
              </text>
            </g>
            <g id="C1.2.GARDEROB">
              <polyline points="496.74 237.91 495.85 263.8 519.11 264.86 524.25 186.49 498.56 185.2 496.97 231.29" />
            </g>
            <g id="C1.2.129" data-name="C1.2.129">
              <polyline points="428.19 239.57 429.76 286.5 407.23 287.84 400.58 186.55 426.34 185.14 427.92 231.83" />
              <text transform="matrix(0.07, 1.02, -1, 0.07, 410.75, 231.33)">
                129
              </text>
            </g>
            <g id="C1.2.124K" data-name="C1.2.124K">
              <polyline points="381.31 195.67 383.43 222.19 340.09 227.07 335.14 193.64 380.75 188.67 381.01 191.84" />
              <text transform="translate(347.55 214.18) scale(0.98 1)">
                124K
              </text>
            </g>
            <g id="C1.2.123K" data-name="C1.2.123K">
              <polyline points="383.95 228.75 386.07 255.27 344.97 260 340.09 227.07 383.43 222.19 383.68 225.35" />
              <text transform="translate(351.37 247.21) scale(0.98 1)">
                123K
              </text>
            </g>
            <g id="C1.2.122K" data-name="C1.2.122K">
              <polyline points="386.28 257.89 386.07 255.27 344.97 260 349.91 293.29 388.73 288.45 386.6 261.81" />
              <text transform="translate(354.24 280.66) scale(0.98 1)">
                122K
              </text>
            </g>
            <g id="C1.2.121K" data-name="C1.2.121K">
              <polyline points="389.25 295.01 391.46 322.74 355.05 327.96 349.91 293.29 388.73 288.45 388.98 291.67" />
              <text transform="translate(359.34 313.16) scale(0.98 1)">
                121K
              </text>
            </g>
          </g>
          <g className="map-groups-walls">
            <polyline points="479.29 187.26 498.46 187.95 496.97 231.29" />
            <line x1="454.7" y1="187.26" x2="468.53" y2="187.26" />
            <path d="M529.43,346.11" transform="translate(-50.14 -158.86)" />
            <polyline points="427.92 231.83 426.44 187.92 446.25 187.26" />
            <line x1="495.25" y1="281.32" x2="493.91" y2="320.17" />
            <line x1="430.91" y1="320.17" x2="428.21" y2="240.15" />
            <line x1="466.76" y1="295.01" x2="473.84" y2="295.01" />
            <line x1="481.92" y1="311.17" x2="494.08" y2="311.17" />
            <line x1="466.6" y1="311.17" x2="473.68" y2="311.17" />
            <line x1="450.91" y1="311.17" x2="457.68" y2="311.17" />
            <line x1="443.2" y1="311.17" x2="430.46" y2="311.17" />
            <line x1="482.05" y1="295.01" x2="494.78" y2="295.01" />
            <line x1="451.98" y1="295.01" x2="458.76" y2="295.01" />
            <line x1="430.06" y1="295.01" x2="443.26" y2="295.01" />
            <text
              className="label"
              transform="translate(435.91 245.15) scale(0.98 1)"
            >
              ATRIUM
            </text>
          </g>
          <g>
            <g className="map-groups-coworking-atameken">
              <path
                d="M473.6,246.2l-13.91.17h0c4.1-.21,8.86-2.62,8.64-6.58h0c-.29-5.19,4.2-9.32,9.58-9.41l12.32-.18.25,15.8-13.36.16"
                transform="translate(-50.14 -158.86)"
              />
              <polyline points="502.7 87.45 504.73 87.51 505.43 71.64 483.07 71.05 483.07 86.82 499.21 87.34" />
              <polyline points="520.58 72.21 505.43 71.64 504.73 87.51 538.23 89.1 541.82 89.27 544.44 89.4 545.15 73.13 524.32 72.35" />
              <polyline points="567.47 91.13 570.4 91.38 571.94 74.52 553.31 73.04 551.97 89.81 563.68 90.81" />
              <polyline points="564.11 151.49 564.28 149.71 547.27 148.63 545.63 170.4 562.21 172.01 563.81 154.76" />
              <polyline points="548.73 130.3 548.87 128.6 566.13 129.72 564.28 149.71 547.27 148.63 548.46 133.69" />
              <polyline points="566.25 128.38 566.13 129.72 548.87 128.6 549.43 121.54 566.79 122.56 566.58 124.81" />
              <polyline points="568.36 105.61 566.79 122.56 549.43 121.54 551.27 98.5 568.9 99.76 568.67 102.2" />
              <polyline points="581.27 95.13 582.3 86.22 605.28 88.49 602.95 104.18 580.45 102.19 580.87 98.57" />
              <polyline points="588.74 86.86 605.28 88.49 609.28 61.48 585.33 57.84 582.3 86.22 584.64 86.45" />
              <path
                d="M428.44,316.87l-.1-1.37c-.53-5-6.13-8.63-11.29-8.17h0a8.07,8.07,0,0,1-8.83-7h0L411,331.11l18.42-1.85-.72-9.06"
                transform="translate(-50.14 -158.86)"
              />
              <path
                d="M426.94,298.09l-.49-6.14-11.58.68a7,7,0,0,0-6.65,7.72h0a8.07,8.07,0,0,0,8.83,7h0c5.16-.46,10.77,3.42,11.3,8.4l-1.08-13.53"
                transform="translate(-50.14 -158.86)"
              />
              <polygon points="494.08 102.92 514.98 103.56 515.18 99.2 494.23 98.51 494.08 102.92" />
            </g>
            <g className="map-groups-coworking-atameken">
              <path
                d="M425.58,282.51l-.08-.64c-.64-4.93-5.74-8.32-10.86-7.83h0a7.64,7.64,0,0,1-8.35-6.23l-2.78-19.74a13.34,13.34,0,0,1,12.37-15l23.47-2a11.47,11.47,0,0,1,12.3,9h0c0,2.42,1.16,2.84,1.67,3.44h0"
                transform="translate(-50.14 -158.86)"
              />
              <path
                d="M408.4,271.86h0c1-17,17.52-36.15,35-40.36h0"
                transform="translate(-50.14 -158.86)"
              />
              <path
                d="M413.31,274.06c.72-19.32,18.46-36.62,35.74-39.12"
                transform="translate(-50.14 -158.86)"
              />
              <path
                d="M418.33,274.35a38.15,38.15,0,0,1,33.22-34.67"
                transform="translate(-50.14 -158.86)"
              />
              <path
                d="M423.18,277.14c.63-18.7,14.38-30.47,30.14-33.65"
                transform="translate(-50.14 -158.86)"
              />
            </g>
            <g className="map-groups-walls">
              <polyline points="355.05 55.67 315.55 61.48 331.97 172.25 360.51 168.97" />
              <line x1="412.3" y1="164.31" x2="379.29" y2="166.99" />
              <line x1="512.31" y1="163.92" x2="545.91" y2="166.99" />
              <polyline points="562.81 169.03 593.08 172.25 602.95 104.18" />
              <polyline points="369.84 54.19 394.92 51.75 428.33 49.75 429.07 71.35" />
              <polyline points="487.69 49.45 494.84 49.69 494.25 71.05" />
              <line x1="448.62" y1="49.18" x2="476.51" y2="49.18" />
              <line x1="437.41" y1="49.44" x2="428.33" y2="49.75" />
              <polyline points="494.84 49.69 528.96 51.68 554.81 54.17" />
              <line x1="571.94" y1="56.06" x2="585.33" y2="57.84" />
              <rect x="454.19" y="69.78" width="13.9" height="2.54" />
              <rect x="454.19" y="84.67" width="13.9" height="2.84" />
              <path
                d="M443.34,278.29l1-.06c8.65-.51,15.48-7.71,14.95-16.07h0L460,277.3Z"
                transform="translate(-50.14 -158.86)"
              />
            </g>
            <text
              className="label"
              transform="translate(417.31 83.87) scale(0.98 1)"
            >
              COWORKING
            </text>
          </g>
          <g className="map-groups-escapes">
            <polyline points="409.05 315.67 409.49 321.35 430.91 320.17 429.76 286.5 407.23 287.84 408.77 311.46" />
            <polyline points="515.98 311.17 517.65 285.69 495.13 284.69 493.83 320.17 515.31 321.35 515.78 314.17" />
            <polyline points="479.87 102.49 485.19 102.65 484.56 163.15 468.56 163.15 468.22 102.13 473.7 102.3" />
          </g>
          <g className="map-groups-techs">
            <polyline points="425.92 163.7 412.3 164.31 409.17 103.56 454.46 102.13 454.47 120.45 454.46 118.22 444.73 118.48 445.14 154.27 454.65 154.09 454.63 150.95 454.7 163.15 437.75 163.15 454.7 163.15 454.65 154.09 445.14 154.27 433.75 154.45 432.93 123.87 414.08 124.37 415.32 155.11 425.51 154.78" />
            <polyline points="454.46 102.13 454.47 120.85 454.46 118.22" />
            <polyline points="500.08 163.38 512.31 163.92 514.98 103.56 485.19 102.65 485.06 115.3 500.34 115.64 500.14 154.37" />
          </g>
          <g className="map-groups-wcs">
            <polyline points="426 154.77 415.32 155.11 413.46 124.39 432.93 123.87 433.75 154.45 431.7 154.52" />
            <polyline points="491.42 154.26 484.57 153.93 485.06 115.3 500.34 115.64 500.14 154.37 497.99 154.37" />
          </g>
        </g>
        <g id="C1.1">
          <g>
            <g className="map-groups-rooms">
              <g data-name="ASSEMBLYHALL|АКТОВЫЙ ЗАЛ">
                <polygon points="191.05 353.41 151.89 238.93 167.79 234.45 189.03 306.16 167.79 234.45 222.96 218.91 243.3 290.38 222.96 218.91 236.82 215 264.45 332.34 191.05 353.41" />
                <g>
                  <text transform="matrix(0.95, -0.26, 0.25, 0.97, 180.49, 291.45)">
                    ASSEMBLY
                  </text>
                  <text transform="matrix(0.95, -0.26, 0.25, 0.97, 202.77, 300.28)">
                    HALL
                  </text>
                </g>
              </g>
              <g>
                <polyline points="149.8 232.96 184.64 334.68 168.11 340.2 179.31 370.29 119.83 394.87 5.48 188.49 1 172.49 3.55 158.73 12.36 147.66 26.52 138.86 41.12 132.98 84.63 221.4 123.9 206.34 130.07 222.03 144.44 217.3 146.8 224.15" />
                <text transform="matrix(0.92, -0.36, 0.35, 0.94, 83.33, 289.34)">
                  LIBRARY
                </text>
              </g>
            </g>
            <g data-name="C1.1.MEDIKER|МЕДИКЕР" fill="#e0706a">
              <polyline
                stroke="#7f7f7f"
                points="194.8 116.89 186.93 86.94 248.21 72.7 255.97 110.05 221.19 117.75 225.37 135.84 201.35 141.82 195.99 121.41"
              />
              <path
                fill="#DF0C00"
                d="M263.56,263.37H250.33a1.52,1.52,0,0,0-1.53,1.52v13.24a1.53,1.53,0,0,0,1.53,1.53h13.23a1.53,1.53,0,0,0,1.53-1.53V264.89A1.52,1.52,0,0,0,263.56,263.37Z"
                transform="translate(-50.14 -158.86)"
              />
              <path
                fill="#ffffff"
                d="M250.84,265.41a1.59,1.59,0,0,0,.09.38c.29.68,1.37,1.49,3.27,2.46a4.69,4.69,0,0,1,1.8,1.32,2.44,2.44,0,0,1,.59,1.5v.11h-2.9l0,.07a.68.68,0,0,0,.18.33,3.69,3.69,0,0,0,1,.57,3.68,3.68,0,0,1,1.43.92,2.44,2.44,0,0,1,.21.3,2,2,0,0,1,.16.51v.1l-.11,0a1.07,1.07,0,0,0-.24,1.48,1,1,0,0,0,.33.33s0,0,0,.9v.88h.08a.88.88,0,0,0,.55-.51,1.3,1.3,0,0,0,.11-.56,1.22,1.22,0,0,0-.23-.76l-.08-.12V275c0-.35,0-.64,0-.64l1.07-.27a9.74,9.74,0,0,0,1.36-.46,2.71,2.71,0,0,0,1.7-2,2.87,2.87,0,0,0,0-1,2.29,2.29,0,0,0-.48-1,1.38,1.38,0,0,0-1-.49,1.45,1.45,0,0,0-1.3.67,2.25,2.25,0,0,0-.31.76v.06h.24a1.27,1.27,0,0,0,1-.46,1.53,1.53,0,0,0,.22-.32c.08-.15.08-.15.16-.15a1,1,0,0,1,.47.15,1.07,1.07,0,0,1,.47.55,1.84,1.84,0,0,1,.14.76,1.67,1.67,0,0,1-.46,1.2,4.38,4.38,0,0,1-2.13,1.13l-1.07.29a.74.74,0,0,1,.07-.31,2.08,2.08,0,0,1,.6-.76,6.31,6.31,0,0,1,.89-.52c.32-.14.87-.41,1-.5s.4-.37.4-.52v-.05H257.3v-.09a2.78,2.78,0,0,1,.1-.6,2.83,2.83,0,0,1,.79-1.25,5.75,5.75,0,0,1,1.66-1.08c1.94-1,3.11-1.94,3.19-2.65v-.11h-1.11c-.67,0-1.19,0-1.28,0a1.86,1.86,0,0,0-1.17.66,5,5,0,0,0-.64.95,4,4,0,0,1-.73,1.07,1.24,1.24,0,0,1-.24.19,1.28,1.28,0,0,1-.44.15h-.06v-.23a1.15,1.15,0,0,0-.29-.9c-.16-.19-.14-.19-.29,0a1.22,1.22,0,0,0-.27.49,1.35,1.35,0,0,0,0,.41v.27h-.1a1.1,1.1,0,0,1-.48-.2,2.37,2.37,0,0,1-.53-.61c-.07-.1-.23-.38-.36-.6s-.29-.49-.35-.59a2,2,0,0,0-1.39-1,8.84,8.84,0,0,0-1.32,0Z"
                transform="translate(-50.14 -158.86)"
              />
            </g>
            <g className="map-groups-techs">
              <polygon points="238.44 191.06 270.99 183.5 268.23 170.9 235.39 178.17 238.44 191.06" />
              <polyline points="267.85 168.96 268.23 170.9 235.39 178.17 232.07 164.13 257.09 158.54 265.47 156.66 267.32 166.25" />
              <polyline points="257.62 158.42 265.47 156.66 262.57 142.06 228.61 149.53 232.07 164.13 254.26 159.17" />
              <polygon points="41.12 132.98 84.63 221.4 123.9 206.34 130.07 222.03 136.77 219.72 118.68 165.08 143.18 157.42 125.46 104.51 41.12 132.98" />
              <polygon points="153.5 112.66 164.64 151.19 143.18 157.42 130.5 119.6 153.5 112.66" />
            </g>
          </g>
          <g className="map-groups-rooms">
            <g id="C1.1.168" data-name="C1.1.168">
              <polyline points="274.54 239.51 244.23 246.45 236.82 215 302.88 201.54 307.7 232.05 279.84 238.32" />
              <text
                className="fit-text"
                transform="translate(264.09 229.11) scale(0.98 1)"
              >
                168
              </text>
            </g>
            <g>
              <polyline points="288.09 247.58 288.82 251.03 288.82 251.03 284.45 251.99 310.05 246.88 307.7 232.05 285.84 236.97 287.32 243.97" />
            </g>
            <g>
              <polyline points="272.86 247.19 271.21 240.26 244.23 246.45 247.58 260.68 274.54 254.26 279.07 253.22 274.54 254.26 273.69 250.68" />
            </g>
            <g id="C1.1.165" data-name="C1.1.165">
              <polyline points="275.18 285.5 280.6 284.18 274.54 254.26 247.58 260.68 254.6 290.51 271.99 286.27" />
              <text transform="translate(254.63 277.56) scale(0.98 1)">
                165
              </text>
            </g>
            <g id="C1.1.164" data-name="C1.1.164">
              <polyline points="307.31 274.86 308.1 279.25 326.66 276.58 323.3 253.92 304.12 257.15 306.65 271.2" />
              <text
                className="fit-text"
                transform="translate(307.7 271) scale(0.98 1)"
              >
                164
              </text>
            </g>
            <g id="C1.1.163" data-name="C1.1.163">
              <polyline points="309.59 287.52 311.24 296.69 329.19 293.65 326.66 276.58 308.1 279.25 309.05 284.49" />
              <text
                className="fit-text"
                transform="translate(311.02 290.51) scale(0.98 1)"
              >
                163
              </text>
            </g>
            <g id="C1.1.156" data-name="C1.1.156">
              <polyline points="299.04 268.7 304.29 297.86 293.39 299.7 286.38 260.78 297.23 258.68 298.41 265.2" />
              <text
                className="fit-text"
                transform="matrix(0.2, 1, -0.98, 0.2, 290.29, 273.09)"
              >
                156
              </text>
            </g>
            <g id="C1.1.155" data-name="C1.1.155">
              <polyline points="295.43 311.02 300.02 336.47 334.71 330.86 329.19 293.65 293.39 299.7 294.78 307.37" />
              <text transform="translate(304.29 319.21) scale(0.98 1)">
                155
              </text>
            </g>
            <g id="C1.1.143" data-name="C1.1.143">
              <polyline points="258.56 122.61 255.97 110.05 221.19 117.75 228.61 149.53 262.57 142.06 259.86 128.92" />
              <text transform="translate(232.07 135.04) scale(0.98 1)">
                143
              </text>
            </g>
            <g id="C1.1.142" data-name="C1.1.142">
              <polyline points="275.6 140.96 275.33 139.56 305.56 134.21 311.72 175.8 283.29 181.27 276.26 144.42" />
              <text transform="translate(283.46 161.71) scale(0.98 1)">
                142
              </text>
            </g>
            <g id="C1.1.141" data-name="C1.1.141">
              <polyline points="275.02 137.97 275.33 139.56 305.56 134.21 300.76 101.87 269.15 107.72 274.43 134.9" />
              <text transform="translate(278.28 126.63) scale(0.98 1)">
                141
              </text>
            </g>
            <g id="C1.1.140" data-name="C1.1.140">
              <polyline points="286.24 93.57 284.94 93.78 280.3 66.65 295.21 64.37 299.22 91.47 289.39 93.06" />
              <text transform="matrix(0.15, 1.01, -0.99, 0.16, 283.49, 70.39)">
                140
              </text>
            </g>
            <g id="C1.1.139" data-name="C1.1.139">
              <polyline points="277.82 94.98 284.94 93.78 280.3 66.65 261.79 69.98 267.04 96.8 274.61 95.52" />
              <text transform="matrix(0.18, 1.01, -0.98, 0.18, 268.27, 72.69)">
                139
              </text>
            </g>
          </g>
          <g className="map-groups-escapes">
            <polygon points="167.12 210.3 181.27 205.93 164.64 151.19 150.8 155.21 167.12 210.3" />
            <polygon points="266.96 344.09 259.76 312.45 276.37 308.75 283.27 340.16 266.96 344.09" />
            <polygon points="194.96 364.83 184.64 334.68 168.11 340.2 179.51 370.81 194.96 364.83" />
          </g>
          <g className="map-groups-techs">
            <polyline points="217.9 196.24 238.44 191.06 225.37 135.84 179.65 147.23 183 159.39 183.81 162.41 183 159.39 192.28 157.03 201.61 191.45 192.58 194.18 191.5 190.58 192.58 194.18 194.76 202.08 211.94 197.75" />
            <polyline points="152.18 214.88 136.77 219.72 118.68 165.08 150.8 155.21 153.26 163.57 153.26 163.57 137.57 167.94 148.84 206.34" />
          </g>
          <g className="map-groups-wcs">
            <polyline points="151.15 205.69 148.84 206.34 137.57 167.94 153.27 163.62 164.28 202.34 156.51 204.45" />
            <polyline points="214.56 188.53 211.14 189.39 203.51 161.03 222.36 156.11 229.1 185.17 219.5 187.55" />
          </g>
        </g>
      </g>
      <Stairs isFirst />
      <IconsEscapeFirstSecond />
    </MapLayout>
  );
};

export default FirstFloorAll;
