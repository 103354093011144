const WALLPAPER_C1_3 = () => {
  return (
    <g id="WALLPAPER">
      <path
        id="C1.3"
        className="label-huge"
        d="M811.52,216.34l-3.5-1a12.08,12.08,0,0,0-2.11-7.72,11.94,11.94,0,0,0-2.91-2.94,12.66,12.66,0,0,0-4-1.88,12.47,12.47,0,0,0-7.11,0,13.72,13.72,0,0,0-6.3,4.24,21.16,21.16,0,0,0-4.26,8.49,21.56,21.56,0,0,0-.75,9.53,14.07,14.07,0,0,0,3.2,6.91,12.53,12.53,0,0,0,6.06,3.75,12.84,12.84,0,0,0,4.38.49,12.27,12.27,0,0,0,4-.95,11.7,11.7,0,0,0,3.35-2.24,11.46,11.46,0,0,0,2.44-3.27l3.49,1a16.07,16.07,0,0,1-3.12,4.45,15.26,15.26,0,0,1-4.34,3.07,15.1,15.1,0,0,1-5.25,1.39,16.23,16.23,0,0,1-5.86-.63,15.87,15.87,0,0,1-7.84-4.85,17.26,17.26,0,0,1-3.93-8.54,28.17,28.17,0,0,1,5.82-21,16.86,16.86,0,0,1,7.75-5.2,15.66,15.66,0,0,1,9.2,0,16.37,16.37,0,0,1,5.33,2.55A15,15,0,0,1,809,206a15.53,15.53,0,0,1,2.12,4.91A16,16,0,0,1,811.52,216.34ZM834,209.73l-10.7,38.52-3.48-1,9.68-34.83-.22-.07-10.59,3.47,1-3.65,10.68-3.48ZM835.27,252a2.75,2.75,0,0,1-1.68-1.33,2.81,2.81,0,0,1-.25-2.14,2.77,2.77,0,0,1,3.43-2,2.68,2.68,0,0,1,1.68,1.33,2.85,2.85,0,0,1-1.74,4.11A2.61,2.61,0,0,1,835.27,252Zm22.06,6.52a15.32,15.32,0,0,1-5.93-3.06,11.33,11.33,0,0,1-3.39-4.84,9.83,9.83,0,0,1-.25-5.77l3.52,1a6.71,6.71,0,0,0,.26,4.05A8.29,8.29,0,0,0,854,253.2a11.16,11.16,0,0,0,4.15,2.11,10.76,10.76,0,0,0,4.92.33,8,8,0,0,0,3.92-1.83,7.31,7.31,0,0,0,2.25-3.62,7.69,7.69,0,0,0,0-4.46,8.11,8.11,0,0,0-2.6-3.74,13,13,0,0,0-4.93-2.47l-2.23-.63.88-3.16,2.23.63a10.45,10.45,0,0,0,4.62.34,7.61,7.61,0,0,0,3.73-1.72,7.08,7.08,0,0,0,2.18-3.58,7.65,7.65,0,0,0,.09-4,7,7,0,0,0-2-3.3A8.74,8.74,0,0,0,867.5,222a9.75,9.75,0,0,0-4.3-.29,7.79,7.79,0,0,0-3.65,1.6,6.92,6.92,0,0,0-2.23,3.41l-3.37-1a9.83,9.83,0,0,1,3.11-4.82,11,11,0,0,1,5.17-2.38,13.29,13.29,0,0,1,6.17.35,12.1,12.1,0,0,1,5.43,3,10.37,10.37,0,0,1,2.76,4.78,10.19,10.19,0,0,1,0,5.45,9.15,9.15,0,0,1-9,7l-.07.24a10,10,0,0,1,4.82,4.9,9.71,9.71,0,0,1,.38,6.79,10.24,10.24,0,0,1-3.14,5.09,11.63,11.63,0,0,1-5.46,2.63A14.27,14.27,0,0,1,857.33,258.48Z"
        transform="translate(-627.38 -198.82)"
      />
      <g className="map-groups-stairs">
        <polyline points="33.3 153.16 20.9 236.79 15.62 236.02 19.66 210 9.67 208.49 5.99 234.62 0.57 233.83 12.88 150.76" />
        <polyline points="48.69 49.6 35.3 139.62 30.04 138.76 34.07 112.74 24.09 111.23 20.42 137.28 15.22 136.6 28.29 46.84" />
        <g>
          <path
            d="M628.76,808.79h13a1.5,1.5,0,0,1,1.5,1.5v13a1.5,1.5,0,0,1-1.5,1.5h-13a1.5,1.5,0,0,1-1.5-1.5v-13A1.5,1.5,0,0,1,628.76,808.79Z"
            transform="translate(-623.23 -594.4)"
          />
          <path
            d="M638.76,813.29v7h-7v-7Zm-8,0v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1v-7a1,1,0,0,0-1-1h-7A1,1,0,0,0,630.76,813.29Zm1.5,1.5h1.21v1.67h1.29v1.67h1.29v1.66h2.21v-1h-1.21v-1.67h-1.29v-1.66h-1.29v-1.67h-2.21Z"
            transform="translate(-623.23 -594.4)"
          />
        </g>
        <g>
          <path
            d="M643.85,711.85h13a1.5,1.5,0,0,1,1.5,1.5v13a1.51,1.51,0,0,1-1.5,1.5h-13a1.5,1.5,0,0,1-1.5-1.5v-13A1.5,1.5,0,0,1,643.85,711.85Z"
            transform="translate(-623.23 -594.4)"
          />
          <path
            d="M653.85,716.35v7h-7v-7Zm-8,0v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1v-7a1,1,0,0,0-1-1h-7A1,1,0,0,0,645.85,716.35Zm1.5,1.5h1.21v1.67h1.29v1.66h1.29v1.67h2.21v-1h-1.21v-1.67h-1.29v-1.67h-1.29v-1.66h-2.21Z"
            transform="translate(-623.23 -594.4)"
          />
        </g>
      </g>
      <path
        className="bg"
        d="M636.11,885.28c35.09,5.31,35.09,5.31,67.75,13.24l2.51-11.75,73.31,21-3.82,11.45c38.34,13.33,38.34,13.33,75.13,30L964.05,745.67c15.88-32.14-4.35-47-29-56.28-152.24-57.12-259.4-70.59-259.4-70.59Z"
        transform="translate(-623.23 -594.4)"
      />
    </g>
  );
};

export default WALLPAPER_C1_3;
