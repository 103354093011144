const WALLPAPER_C1_2 = () => {
  return (
    <g id="WALLPAPER">
      <path
        id="C1.2"
        className="label-huge"
        d="M496.72,171.67h-3.6a11.16,11.16,0,0,0-1.46-3.72,11.42,11.42,0,0,0-2.61-3,12,12,0,0,0-3.57-2,12.82,12.82,0,0,0-4.31-.71,12.49,12.49,0,0,0-6.79,1.94,13.69,13.69,0,0,0-4.89,5.69,24.09,24.09,0,0,0,0,18.37,13.66,13.66,0,0,0,4.89,5.68,12.56,12.56,0,0,0,6.79,1.92,12.82,12.82,0,0,0,4.31-.71,12,12,0,0,0,3.57-2,11.75,11.75,0,0,0,4.07-6.75h3.6a15.78,15.78,0,0,1-1.8,5.05,15.18,15.18,0,0,1-8,6.78,16.22,16.22,0,0,1-5.77,1,15.81,15.81,0,0,1-8.78-2.47,16.81,16.81,0,0,1-6-7,27.37,27.37,0,0,1,0-21.36,16.69,16.69,0,0,1,6-7,15.74,15.74,0,0,1,8.78-2.49,16.44,16.44,0,0,1,5.77,1,14.47,14.47,0,0,1,4.64,2.73,15,15,0,0,1,3.34,4.06A15.61,15.61,0,0,1,496.72,171.67Zm19.7-12.28v39.28h-3.59V163.15h-.23l-9.2,6.12v-3.72l9.28-6.16ZM528.88,199a2.75,2.75,0,0,1-2.76-2.76,2.63,2.63,0,0,1,.81-1.95,2.76,2.76,0,0,1,3.91,0,2.63,2.63,0,0,1,.81,1.95,2.57,2.57,0,0,1-.39,1.38,2.84,2.84,0,0,1-1,1A2.69,2.69,0,0,1,528.88,199Zm11.65-.28V196l12.83-14.42q2.5-2.84,4-4.85a16.64,16.64,0,0,0,2.23-3.72,9.22,9.22,0,0,0,.71-3.55,7.16,7.16,0,0,0-3.91-6.54,8.82,8.82,0,0,0-4.11-.94,8.46,8.46,0,0,0-4.24,1,7.23,7.23,0,0,0-2.79,2.83,8.41,8.41,0,0,0-1,4.15h-3.43a11.06,11.06,0,0,1,1.49-5.78,10.91,10.91,0,0,1,4.12-4,12.32,12.32,0,0,1,5.95-1.43,11.76,11.76,0,0,1,5.83,1.42,10.5,10.5,0,0,1,4,3.81,10.27,10.27,0,0,1,1.45,5.41,11.86,11.86,0,0,1-.76,4.2,19.16,19.16,0,0,1-2.59,4.52q-1.85,2.5-5.1,6.1l-9.74,10.87v.25h19.42v3.23Z"
        transform="translate(-348.54 -158.86)"
      />
      <g className="map-groups-stairs">
        <polyline points="312.13 193.13 299.74 276.76 294.46 275.99 298.49 249.97 288.51 248.46 284.83 274.59 279.41 273.8 291.72 190.73" />
        <polyline points="327.53 89.56 314.14 179.59 308.88 178.73 312.91 152.71 302.93 151.2 299.26 177.25 294.06 176.57 307.12 86.81" />
        <g>
          <path
            d="M632.91,413.22h13a1.5,1.5,0,0,1,1.5,1.5v13a1.5,1.5,0,0,1-1.5,1.5h-13a1.5,1.5,0,0,1-1.5-1.5v-13A1.5,1.5,0,0,1,632.91,413.22Z"
            transform="translate(-348.54 -158.86)"
          />
          <path
            d="M642.91,417.72v7h-7v-7Zm-8,0v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1v-7a1,1,0,0,0-1-1h-7A1,1,0,0,0,634.91,417.72Zm1.5,1.5h1.21v1.67h1.29v1.66h1.29v1.67h2.21v-1H641.2v-1.67h-1.29v-1.67h-1.29v-1.66h-2.21Z"
            transform="translate(-348.54 -158.86)"
          />
        </g>
        <g>
          <path
            d="M648,316.28h13a1.5,1.5,0,0,1,1.5,1.5v13a1.5,1.5,0,0,1-1.5,1.5H648a1.51,1.51,0,0,1-1.5-1.5v-13A1.5,1.5,0,0,1,648,316.28Z"
            transform="translate(-348.54 -158.86)"
          />
          <path
            d="M658,320.78v7h-7v-7Zm-8,0v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1v-7a1,1,0,0,0-1-1h-7A1,1,0,0,0,650,320.78Zm1.5,1.5h1.21V324H654v1.66h1.29v1.67h2.21v-1h-1.21v-1.67H655v-1.67H653.7v-1.66h-2.21Z"
            transform="translate(-348.54 -158.86)"
          />
        </g>
      </g>
      <g className="map-groups-stairs">
        <polyline points="15.89 193.13 28.22 276.64 33.62 275.85 29.93 250.08 39.35 248.71 43.07 274.47 48.55 273.68 36.18 189.88" />
        <polyline points="0.49 89.56 13.88 179.59 19.32 178.62 15.63 152.85 25.05 151.48 28.77 177.25 34.18 176.37 20.9 86.81" />
        <g>
          <path
            d="M390.93,412.75h-13a1.5,1.5,0,0,0-1.5,1.5v13a1.5,1.5,0,0,0,1.5,1.5h13a1.5,1.5,0,0,0,1.5-1.5v-13A1.5,1.5,0,0,0,390.93,412.75Z"
            transform="translate(-348.54 -158.86)"
          />
          <path
            d="M387.93,417.25v7h-7v-7Zm0-1h-7a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1v-7A1,1,0,0,0,387.93,416.25Zm-.5,1.5h-2.21v1.67h-1.29v1.66h-1.29v1.67h-1.21v1h2.21v-1.66h1.29v-1.67h1.29v-1.67h1.21Z"
            transform="translate(-348.54 -158.86)"
          />
        </g>
        <g>
          <path
            d="M377.7,316.77h-13a1.5,1.5,0,0,0-1.5,1.5v13a1.5,1.5,0,0,0,1.5,1.5h13a1.5,1.5,0,0,0,1.5-1.5v-13A1.5,1.5,0,0,0,377.7,316.77Z"
            transform="translate(-348.54 -158.86)"
          />
          <path
            d="M374.7,321.27v7h-7v-7Zm0-1h-7a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1v-7A1,1,0,0,0,374.7,320.27Zm-.5,1.5H372v1.66H370.7v1.67h-1.29v1.67H368.2v1h2.21V326.1h1.29v-1.66H373v-1.67h1.21Z"
            transform="translate(-348.54 -158.86)"
          />
        </g>
      </g>

      <path
        className="bg"
        d="M405.19,486.82c37.62-5.89,37.58-5.8,75.86-7.8l-.3-9h63.61l-.31,9c37.8,2,37.76,1.83,75.87,7.8l39.5-266.49c-100.92-16.54-192.81-16.54-293.73,0Z"
        transform="translate(-348.54 -158.86)"
      />
    </g>
  );
};

export default WALLPAPER_C1_2;
